import {
  ChoiceGroup, FontIcon, IChoiceGroupOption, mergeStyles, Stack, TextField,
} from '@fluentui/react';
import { CategoryType } from 'models/categoryType';
import React, {
  FunctionComponent, useCallback, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import { useId } from '@fluentui/react-hooks';
import { SaveButton } from 'components/saving/saveButton';
import { ValidationType } from 'models/validationType';
import { FormContext } from 'components/formProvider/formContext';
import { FormToolTab } from 'models/formToolTab';
import { CategorySection } from 'components/categorySection/categorySection';
import { CheckboxWithToolTip } from 'components/checkboxWithTooltip/checkboxWithTooltip';

const categoryOptions: IChoiceGroupOption[] = [
  { key: CategoryType.GovernmentEntity, text: 'US Federal, State, Local, or Tribal government entity' },
  { key: CategoryType.SolutionProvider, text: 'Solution provider serving US federal, state, local or tribal government entities', styles: { root: { marginTop: -1 } } },
  { key: CategoryType.Customers, text: 'Customers handling government-controlled data', styles: { root: { marginTop: -3 } } },
];

const enum ContractType {
  C2E = 'C2E',
  GSA = 'GSA',
  JWCC = 'JWCC',
  Other = 'Other',
}

const contractTypeOptions: IChoiceGroupOption[] = [
  { key: ContractType.C2E, text: ContractType.C2E, styles: { root: { marginTop: -1 } } },
  { key: ContractType.GSA, text: ContractType.GSA, styles: { root: { marginTop: -1 } } },
  { key: ContractType.JWCC, text: ContractType.JWCC, styles: { root: { marginTop: -1 } } },
  { key: ContractType.Other, text: ContractType.Other, styles: { root: { marginTop: -1 } } },
];

const formStyle = mergeStyles({
  marginTop: '2em',
  maxWidth: '600px',
});

const choiceGroupWrapperStyle = mergeStyles({
  marginTop: '3em',
});

const choiceGroupLabelStyle = mergeStyles({
  fontWeight: 'bold',
});

const requiredIconStyles = mergeStyles({
  verticalAlign: 'super', marginLeft: '4px', color: '#9f2d30', fontSize: '0.4em',
});

const textFieldStyles = mergeStyles({
  maxWidth: 290,
  label: {
    fontWeight: 600,
  },
});

const stackTokens = { childrenGap: 10 };

export interface CategoryTabProps {
  changeActiveTab(targetTab: number): void;
}

export const CategoryTab: FunctionComponent<CategoryTabProps> = (props) => {
  const { changeActiveTab } = props;
  const formContext = useContext(FormContext);
  const [tenantDomain, setTenantDomain] = useState<string | undefined>(formContext.formData.categoryInfo?.tenantDomain);
  const [tenantUsername, setTenantUsername] = useState<string | undefined>(formContext.formData.categoryInfo?.tenantUsername);
  const [enrollmentNumber, setEnrollmentNumber] = useState<string | undefined>(formContext.formData.categoryInfo?.enrollmentNumber);
  const [partnerTenantGUID, setPartnerTenantGUID] = useState<string | undefined>(formContext.formData.categoryInfo?.partnerTenantGUID);
  const [category, setCategory] = useState<string | undefined>(formContext.formData.categoryInfo?.categoryType ? formContext.formData.categoryInfo?.categoryType : undefined);
  const getSavedValidations = () => {
    const tempValidations = new Map();
    formContext.formData.categoryInfo?.validationTypes?.forEach((key) => { tempValidations?.set(key, true); });
    return tempValidations;
  };
  const [validations, setValidations] = useState<Map<string, boolean>>(getSavedValidations);
  const [buttonDisabled, setbuttonDisabled] = useState<boolean>(true);
  const [taskOrderID, setTaskOrderID] = useState<string | undefined>(formContext.formData.categoryInfo?.taskOrderID);
  const [contractType, setContractType] = useState<string | undefined>(formContext.formData.categoryInfo?.contractType);
  const [guidErrorMessage, setGuidErrorMessage] = useState<string>();

  const [enrollmentNumberErrorMessage, setEnrollmentNumberErrorMessage] = useState<string>();
  const [tenantDomainErrorMessage, setTenantDomainErrorMessage] = useState<string>();

  const checkBoxId: string = useId('checkbox-');
  const alphaNumericRegex = /^[a-z0-9]+$/i;

  const textInputLimit = 27;
  const azureTenantSectionKey = 'AZURE_TENANT_SECTION';

  const onCategorySelect = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    setValidations(new Map());
    setCategory(option?.key);
  };

  const onValidationChanged = (key: string, checked?: boolean): void => {
    const tempValidations = new Map(validations);
    if (!checked) {
      tempValidations.delete(key);
    } else {
      tempValidations.set(key, !!checked);
    }
    setValidations(tempValidations);
  };

  const isValidationSelected = useCallback((validation: string) => validations.get(validation) || false, [validations]);

  useEffect(() => {
    let selectionIncomplete = true;
    let inputsInvalid = false;
    // checking if validations selected
    validations.forEach((value, key) => {
      if (value) {
        selectionIncomplete = false;
        // check input fields are valid
        switch (key) {
          case ValidationType.AzureGovernmentTrial:
          case ValidationType.AzureGovernmentCSP:
            inputsInvalid = inputsInvalid || !(tenantDomain && tenantUsername) || !!(tenantDomainErrorMessage);
            break;
          case ValidationType.ActivateANewAzureGovernmentEnrollment:
            inputsInvalid = inputsInvalid || !(tenantDomain && tenantUsername && enrollmentNumber) || !!(enrollmentNumberErrorMessage);
            break;
          case ValidationType.TaskOrderValidation:
            inputsInvalid = inputsInvalid || !(tenantDomain && tenantUsername && contractType && taskOrderID);
            break;
          case ValidationType.O365GCCCSPCustomer:
            inputsInvalid = inputsInvalid || !(partnerTenantGUID) || !!(guidErrorMessage);
            break;
          case ValidationType.O365GCCCSPPartner:
            inputsInvalid = inputsInvalid || !(partnerTenantGUID) || !!(guidErrorMessage);
            break;
          default:
            break;
        }
      }
    });

    if (inputsInvalid) {
      setbuttonDisabled(inputsInvalid);
    } else {
      setbuttonDisabled(selectionIncomplete);
    }
  }, [partnerTenantGUID, tenantDomain, tenantUsername, validations, category, isValidationSelected, contractType, taskOrderID, enrollmentNumber, guidErrorMessage, enrollmentNumberErrorMessage, tenantDomainErrorMessage]);

  useEffect(() => {
    if (!validations.get(ValidationType.TaskOrderValidation) && !validations.get(ValidationType.AzureGovernmentTrial)
     && !validations.get(ValidationType.AzureGovernmentCSP) && !validations.get(ValidationType.ActivateANewAzureGovernmentEnrollment)) {
      setTenantDomain('');
      setTenantUsername('');
    }

    if (!validations.get(ValidationType.O365GCCCSPCustomer) && !validations.get(ValidationType.O365GCCCSPPartner)) {
      setPartnerTenantGUID('');
      setGuidErrorMessage('');
    }

    if (!validations.get(ValidationType.ActivateANewAzureGovernmentEnrollment)) {
      setEnrollmentNumber('');
      setEnrollmentNumberErrorMessage('');
    }
  }, [validations]);

  const onNextClicked = () => {
    const selectedValidations: string[] = [];
    validations.forEach((value, key) => {
      if (value) {
        selectedValidations.push(key);
      }
    });
    formContext.updateCategoryInfo({
      categoryType: category, validationTypes: selectedValidations, tenantDomain, tenantUsername, enrollmentNumber, partnerTenantGUID, contractType, taskOrderID,
    });
    changeActiveTab(FormToolTab.OrganizationInfo);
  };

  function validateInputForInvalidCharacters(value: string | undefined) {
    const invalidCharRegex = /[<>]/; // a regular expression that matches < or >
    if (value && value.match(invalidCharRegex)) {
      return 'Characters `<` and `>` are not allowed';
    }
    return '';
  }

  useEffect(() => {
    setEnrollmentNumberErrorMessage(validateInputForInvalidCharacters(enrollmentNumber));
  }, [enrollmentNumber]);

  useEffect(() => {
    setTenantDomainErrorMessage(validateInputForInvalidCharacters(tenantDomain));
  }, [tenantDomain]);

  useEffect(() => {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (partnerTenantGUID && !partnerTenantGUID.match(guidRegex)) {
      setGuidErrorMessage('Enter a valid GUID');
    } else { setGuidErrorMessage(validateInputForInvalidCharacters(partnerTenantGUID)); }
  }, [partnerTenantGUID]);

  const onTenantDomainChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (!newValue) {
      setTenantDomain('');
    } else if (newValue && newValue.length <= textInputLimit && newValue.match(alphaNumericRegex)) {
      setTenantDomain(newValue);
    }
  };

  const onTenantUsernameChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (!newValue) {
      setTenantUsername('');
    } else if (newValue && newValue.length <= textInputLimit && newValue.match(alphaNumericRegex)) {
      setTenantUsername(newValue);
    }
  };

  const onEnrollmentNumberChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (!newValue) {
      setEnrollmentNumber('');
      // Open text box - no limit
    } else if (newValue) {
      setEnrollmentNumber(newValue);
    }
  };

  const onPartnerTenantGUIDChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    setPartnerTenantGUID(newValue);
  };

  const onContractTypeSelect = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    setContractType(option?.key);
  };

  const getErrorMessage = (value?: string) => {
    // check if the input value is empty
    if (!value) {
      return undefined; // clear the error message
    }
    // check if the input value is longer than the text input limit
    if (value.length > textInputLimit) {
      return `Task Order ID must be ${textInputLimit} characters or less`; // set the error message
    }
    // check if the input value contains < or > characters
    if (value.match(/[<>]/)) {
      return 'Characters `<` and `>` are not allowed'; // set the error message
    }
    // otherwise, return undefined
    return undefined;
  };

  const getTaskOrderView = () => (
    <div>
      <div className={choiceGroupWrapperStyle}>
        <div className={choiceGroupLabelStyle}>
          Select your contract Type
          <FontIcon
            aria-label="Required"
            iconName="AsteriskSolid"
            className={requiredIconStyles}
          />
        </div>
        <ChoiceGroup
          required
          options={contractTypeOptions}
          onChange={onContractTypeSelect}
          defaultSelectedKey={contractType}
        />
      </div>
      <div className={choiceGroupWrapperStyle}>
        <TextField
          label="Task Order ID"
          placeholder="Enter a task order ID"
          required
          value={taskOrderID}
          className={textFieldStyles}
          onChange={(_event, newValue) => { setTaskOrderID(newValue); }}
          errorMessage={getErrorMessage(taskOrderID)}
        />
      </div>
    </div>
  );

  const getAzureGovernmentEnrollmentView = () => (
    <section aria-label="Azure Government Enrollment Number" role="form">
      <CategorySection
        categoryInputOneLabel="Azure Government Enrollment Number"
        categoryInputOnePlaceholder="Enter an Enrollment Number"
        categoryInputOneDefaultValue={enrollmentNumber}
        onCategoryInputOneChange={onEnrollmentNumberChange}
        categoryInputOneRequired
        categoryInputOneErrorMessage={enrollmentNumberErrorMessage}
      />
    </section>
  );

  const addTenantSection = (tenantSectionAdded: boolean, elements: JSX.Element[], key: string): void => {
    if (!tenantSectionAdded) {
      elements.push(getSectionDivider(key));
      elements.push(
        <section role="form" aria-label="Azure Government Tenant Information">
          <CategorySection
            title="Azure Government Tenant Information"
            key={azureTenantSectionKey}
            subTitle="Please provide a new desired Azure Government tenant domain and username."
            toolTipText="Please provide a new desired Azure Government tenant domain and username. These must contain letters and numbers only. This must be a unique domain that is not already in use. Your tenant credentials will be in the following format once generated: username@domain.onmicrosoft.us. Please only list the username and domain. Do not include the 'onmicrosoft.us' suffix."
            categoryInputOneLabel="Desired Domain"
            categoryInputOnePlaceholder="Enter tenant domain"
            categoryInputOneDefaultValue={tenantDomain}
            onCategoryInputOneChange={onTenantDomainChange}
            categoryInputOneRequired
            categoryInputTwoLabel="Desired Username"
            categoryInputTwoPlaceholder="Enter username"
            categoryInputTwoDefaultValue={tenantUsername}
            onCategoryInputTwoChange={onTenantUsernameChange}
            categoryInputTwoRequired
            categoryInputOneErrorMessage={tenantDomainErrorMessage}
          />
        </section>,
      );
    }
  };

  const getSectionElements = () => {
    const elements: JSX.Element[] = [];
    let tenantSectionAdded = false;
    let azureTenantSectionIndex = -1;
    validations.forEach((value, key) => {
      switch (key) {
        case ValidationType.AzureGovernmentTrial:
        case ValidationType.AzureGovernmentCSP:
          addTenantSection(tenantSectionAdded, elements, key);
          tenantSectionAdded = true;
          break;
        case ValidationType.ActivateANewAzureGovernmentEnrollment:
          addTenantSection(tenantSectionAdded, elements, key);
          tenantSectionAdded = true;
          azureTenantSectionIndex = elements.findIndex((element) => element.key === azureTenantSectionKey);

          // task order to be added in Azure Tenant section only
          if (azureTenantSectionIndex !== -1) {
            elements.splice(azureTenantSectionIndex + 1, 0, getAzureGovernmentEnrollmentView());
          } else {
            elements.push(getAzureGovernmentEnrollmentView());
          }

          break;
        case ValidationType.TaskOrderValidation:
          addTenantSection(tenantSectionAdded, elements, key);
          tenantSectionAdded = true;
          azureTenantSectionIndex = elements.findIndex((element) => element.key === azureTenantSectionKey);

          // task order to be added in Azure Tenant section only
          if (azureTenantSectionIndex !== -1) {
            elements.splice(azureTenantSectionIndex + 1, 0, getTaskOrderView());
          } else {
            elements.push(getTaskOrderView());
          }

          break;
        case ValidationType.O365GCCCSPCustomer:
          elements.push(getSectionDivider(key));
          elements.push(
            <section role="form" aria-label="O365 GCC CSP Customer, Direct Partner Tenant Information">
              <CategorySection
                title="O365 GCC CSP Customer, Direct Partner Tenant Information"
                tooltipProps={{
                  onRenderContent: () => (
                    <div>
                      This is your
                      {' '}
                      <b>direct</b>
                      {' '}
                      CSP partner’s tenant ID.  Directions for your direct CSP partner to find the correct ID can be found in the
                      {' '}
                      <a href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE3Hj5y" target="_blank" rel="noopener noreferrer">O365 GCC CSP Step-by-Step Guide</a>
                      .
                    </div>
                  ),
                }}
                categoryInputOneLabel="Existing Direct CSP Partner Tenant GUID"
                categoryInputOnePlaceholder="Enter a valid GUID"
                categoryInputOneErrorMessage={guidErrorMessage}
                categoryInputOneDefaultValue={partnerTenantGUID}
                onCategoryInputOneChange={onPartnerTenantGUIDChange}
                categoryInputOneRequired
              />
            </section>,
          );
          break;
        case ValidationType.O365GCCCSPPartner:
          elements.push(getSectionDivider(key));
          elements.push(
            <CategorySection
              title="O365 GCC CSP Partner Tenant Information "
              tooltipProps={{
                onRenderContent: () => (
                  <div>
                    This is your direct CSP partner tenant ID.  Directions to find the correct direct partner tenant ID can be found in the
                    {' '}
                    <a href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE3Hj5y" target="_blank" rel="noopener noreferrer">O365 GCC CSP Step-by-Step Guide</a>
                    .
                  </div>
                ),
              }}
              categoryInputOneLabel="Existing Direct CSP Partner Tenant GUID"
              categoryInputOnePlaceholder="Enter a valid GUID"
              categoryInputOneErrorMessage={guidErrorMessage}
              categoryInputOneDefaultValue={partnerTenantGUID}
              onCategoryInputOneChange={onPartnerTenantGUIDChange}
              categoryInputOneRequired
            />,
          );
          break;
        default:
      }
    });
    return elements;
  };

  const getSectionDivider = (key: string) => (
    <div
      key={key}
      style={{
        borderTop: '2px solid #EFEFEF ', minWidth: 600, marginTop: '1.5em', marginBottom: '2em',
      }}
    />
  );

  const getCheckboxId = (label: string): string => `${checkBoxId}-${_.kebabCase(label)}`;

  return (
    <div className={formStyle} aria-label="category">
      Select the category which best describes your organization:
      {' '}
      (
      <i>Partners cannot submit on behalf of their customers.  If you are a partner, please have your customer submit their own validation request as this form constitutes a legal binding contract.</i>
      )

      <section aria-label="Select a category">
        <div className={choiceGroupWrapperStyle}>
          <div id="category-label" className={choiceGroupLabelStyle}>
            Select a category
          </div>
          <ChoiceGroup
            options={categoryOptions}
            defaultSelectedKey={category}
            onChange={onCategorySelect}
            ariaLabelledBy="category-label"
          />
        </div>
      </section>
      {category
        && (
          <section aria-label="Select the validation or service that you need" role="form">
            <Stack className={choiceGroupWrapperStyle} tokens={stackTokens}>
              <div className={choiceGroupLabelStyle}>
                Select the validation or service that you need
              </div>
              <CheckboxWithToolTip
                checkBoxProps={{
                  id: getCheckboxId(ValidationType.GeneralValidation),
                  label: ValidationType.GeneralValidation,
                  checked: !!validations.get(ValidationType.GeneralValidation),
                  onChange: (_event, checked) => { onValidationChanged(ValidationType.GeneralValidation, checked); },
                }}
                onRenderToolTip={() => (
                  <div>
                    This is used solely to validate customer eligibility to purchase/use the Microsoft Government Cloud.
                    {' '}
                    <br />
                    <br />
                    This
                    {' '}
                    <b>will not</b>
                    {' '}
                    trigger tenant creation in Azure Government.
                  </div>
                )}
              />

              <CheckboxWithToolTip
                checkBoxProps={{
                  id: getCheckboxId(ValidationType.AzureGovernmentTrial),
                  label: ValidationType.AzureGovernmentTrial,
                  checked: !!validations.get(ValidationType.AzureGovernmentTrial),
                  onChange: (_event, checked) => { onValidationChanged(ValidationType.AzureGovernmentTrial, checked); },
                }}
                onRenderToolTip={() => (
                  <ul>
                    <li>
                      This will validate eligibility and provide a free 90-day trial of Azure Government if validation eligibility is approved.
                    </li>
                    <li>
                      Select this option if a new tenant is needed for GCC-H use. If approved, additional steps will need to be taken to properly prepare the tenant for
                      {' '}
                      <span style={{ whiteSpace: 'nowrap' }}>GCC-H</span>
                      {' '}
                      use outside of this validation process.
                    </li>
                    <li>
                      This
                      {' '}
                      <b>will</b>
                      {' '}
                      trigger a new tenant creation in Azure Government.
                    </li>
                  </ul>
                )}
              />

              {(category === CategoryType.SolutionProvider) && (
                <CheckboxWithToolTip
                  checkBoxProps={{
                    id: getCheckboxId(ValidationType.AzureGovernmentCSP),
                    label: ValidationType.AzureGovernmentCSP,
                    checked: !!validations.get(ValidationType.AzureGovernmentCSP),
                    onChange: (_event, checked) => { onValidationChanged(ValidationType.AzureGovernmentCSP, checked); },
                  }}
                  onRenderToolTip={() => (
                    <ul>
                      <li>
                        This will validate eligibility and set the organization on the path to begin registration as an
                        {' '}
                        <b>Azure Government CSP</b>
                        {' '}
                        if validation eligibility is approved.
                      </li>
                      <li>
                        Please note, this is only for the
                        {' '}
                        <b>Azure</b>
                        {' '}
                        Government CSP program.  O365 has a separate Government CSP program.  Partners needing to enroll for the O365 GCC CSP Partner program should check the “O365 GCC CSP Partner” box below.
                        {' '}
                      </li>
                      <li>
                        This
                        {' '}
                        <b>will</b>
                        {' '}
                        trigger tenant creation in Azure Government.
                      </li>
                    </ul>
                  )}
                />
              )}

              {((category === CategoryType.GovernmentEntity) || (category === CategoryType.Customers)) && (
                <CheckboxWithToolTip
                  checkBoxProps={{
                    id: getCheckboxId(ValidationType.O365GCCCSPCustomer),
                    label: ValidationType.O365GCCCSPCustomer,
                    checked: !!validations.get(ValidationType.O365GCCCSPCustomer),
                    onChange: (_event, checked) => { onValidationChanged(ValidationType.O365GCCCSPCustomer, checked); },
                  }}
                  onRenderToolTip={() => (
                    <ul>
                      <li>
                        This will validate eligibility and tie the organization being validated to their Direct Partner’s tenant ID if validation eligibility is approved.
                      </li>
                      <li>
                        All company and contact information must be the customer organization being validated, but the tenant ID provided must be the Direct Partner tenant ID.
                      </li>
                      <li>
                        Directions for your partner to find their correct Direct Partner tenant ID can be found here:
                        {' '}
                        <a href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE3Hj5y" target="_blank" rel="noopener noreferrer">O365 GCC CSP Step-by-Step Guide</a>
                        .
                        {' '}
                      </li>
                    </ul>
                  )}
                />
              )}

              <CheckboxWithToolTip
                checkBoxProps={{
                  id: getCheckboxId(ValidationType.O365GCCTrial),
                  label: ValidationType.O365GCCTrial,
                  checked: !!validations.get(ValidationType.O365GCCTrial),
                  onChange: (_event, checked) => { onValidationChanged(ValidationType.O365GCCTrial, checked); },
                }}
                onRenderToolTip={() => (
                  <ul>
                    <li>
                      This will validate eligibility and provide a free 30-day, 25-user
                      {' '}
                      <b>O365 GCC Trial</b>
                      {' '}
                      code if validation eligibility is approved.
                    </li>
                    <li>
                      Please note, this is an O365
                      {' '}
                      <b>GCC</b>
                      {' '}
                      trial only.
                    </li>
                    <li>
                      Microsoft does not offer GCC High trials, but an approved organization may purchase
                      {' '}
                      <b>O365 GCC High</b>
                      {' '}
                      if validated and approved as eligible.  Purchasing can be done through a reseller, which is a process independent of this validation process.
                    </li>
                    <li>
                      Entities only seeking to purchase
                      {' '}
                      <b>GCC High</b>
                      {' '}
                      will need an unused tenant. If a new tenant is required, select Azure Government Trial or Activate a New Azure Government Enrollment (if you have an active Enterprise Agreement).  Entities should select
                      {' '}
                      <u>General Validation and Azure Government Trial/ Activate a New Azure Government Enrollment above.</u>
                      {' '}
                    </li>
                    <li>
                      This
                      {' '}
                      <b>will</b>
                      {' '}
                      result in a link being provided to activate a GCC tenant for the Government Community Cloud.
                    </li>
                  </ul>
                )}
              />

              {category === CategoryType.SolutionProvider && (
                <CheckboxWithToolTip
                  checkBoxProps={{
                    id: getCheckboxId(ValidationType.O365GCCCSPPartner),
                    label: ValidationType.O365GCCCSPPartner,
                    checked: !!validations.get(ValidationType.O365GCCCSPPartner),
                    onChange: (_event, checked) => { onValidationChanged(ValidationType.O365GCCCSPPartner, checked); },
                  }}
                  onRenderToolTip={() => (
                    <ul>
                      <li>
                        This will validate eligibility and set the organization on the path to begin registration as an
                        {' '}
                        <b>O365 GCC CSP Partner</b>
                        {' '}
                        if validation eligibility is approved.  This program is only available for
                        {' '}
                        <b>Direct Partners</b>
                        {' '}
                        .
                      </li>
                      <li>
                        Partners can verify if they are already a Direct Partner here:
                        {' '}
                        <a href="https://partner.microsoft.com/en-US/membership/cloud-solution-provider/assessment" target="_blank" rel="noopener noreferrer">Cloud Solution Provider (CSP) Assessment</a>
                        .
                      </li>
                      <li>
                        Partners needing to register as a Direct Partner may do so here:
                        {' '}
                        <a href="https://docs.microsoft.com/en-us/partner-center/direct-partner-new-requirements" target="_blank" rel="noopener noreferrer">Enroll as a CSP Direct Bill Partner.</a>
                      </li>
                      <li>
                        Please note, this is only for the
                        {' '}
                        <b>O365</b>
                        {' '}
                        GCC CSP Partner program. Azure has a separate Government CSP program.  Partners needing to enroll for the Azure Government CSP program should check the “Azure Government CSP” box above.
                      </li>
                    </ul>
                  )}
                />
              )}

              {/* 09/12/2023: Commenting this out as per story: https://msazure.visualstudio.com/Azure%20Mission%20Platform%20GRC/_workitems/edit/25025893 - this is intended to come back but should be hidden from the user temporarily.
              {category === CategoryType.GovernmentEntity && (
                <CheckboxWithToolTip
                  checkBoxProps={{
                    id: getCheckboxId(ValidationType.TaskOrderValidation),
                    label: ValidationType.TaskOrderValidation,
                    checked: !!validations.get(ValidationType.TaskOrderValidation),
                    onChange: (_event, checked) => { onValidationChanged(ValidationType.TaskOrderValidation, checked); },
                  }}
                  toolTipText="This option applies to direct awards between government entities and Microsoft which will trigger a tenant creation in Azure Government."
                />
              )} */}

              <CheckboxWithToolTip
                checkBoxProps={{
                  id: getCheckboxId(ValidationType.ActivateANewAzureGovernmentEnrollment),
                  label: ValidationType.ActivateANewAzureGovernmentEnrollment,
                  checked: !!validations.get(ValidationType.ActivateANewAzureGovernmentEnrollment),
                  onChange: (_event, checked) => { onValidationChanged(ValidationType.ActivateANewAzureGovernmentEnrollment, checked); },
                }}
                onRenderToolTip={() => (
                  <ul>
                    <li>
                      This option should only be selected if the entity has an
                      {' '}
                      <b>active</b>
                      {' '}
                      Enterprise Agreement number
                      {' '}
                      <b>and</b>
                      {' '}
                      requires a new tenant.
                    </li>
                    <li>
                      This option can be selected if the entity has an active Enterprise Agreement number and intends to add a GCC-H license to the newly created tenant. Note: GCC-H licenses can only be added to an unused Azure Government tenant.
                    </li>
                    <li>
                      The organization’s contract and enterprise agreement number information submitted must be for the same organization seeking eligibility.
                    </li>
                    <li>
                      This
                      {' '}
                      <b>will</b>
                      {' '}
                      trigger a new tenant creation in Azure Government.
                    </li>
                  </ul>
                )}
              />
              {getSectionElements()}
            </Stack>
          </section>
        )}
      <SaveButton className={choiceGroupWrapperStyle} defaultText="Next" onSave={onNextClicked} disabled={buttonDisabled} />
    </div>
  );
};
