import {
  CountryRegion, FormPostClient, FormPostCommand, StateProvinceList,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import countryStatesJson from '../../generated/countryRegion-StateProvince.json';

export const getRegions = (): CountryRegion[] => {
  // Claudia 02/6/2024: External API that was being used was moved and caused Sev2 incident. Using backup JSON to provide these values.
  const countriesRegions = Array.from(new Set(countryStatesJson.map((countryState) => countryState.CountryRegionName))).map((countryRegion) => ({ countryRegionID: countryRegion, countryRegionName: countryRegion } as CountryRegion));
  return countriesRegions;
};

export const getStates = (regionId: string): StateProvinceList[] => {
  // Claudia 02/6/2024: External API that was being used was moved and caused Sev2 incident. Using backup JSON to provide these values.
  const filteredCountryRegions = countryStatesJson.filter((countryState) => countryState.CountryRegionName === regionId);
  return Array.from(filteredCountryRegions).map((filteredCountryRegion) => ({ stateProvinceId: filteredCountryRegion.StateProvinceName, stateProvinceName: filteredCountryRegion.StateProvinceName } as StateProvinceList));
};

export const submitForm = async (formData : string) : Promise<string> => {
  const formClient = new FormPostClient(getConfig().apiBaseUri);
  const command = { formData } as FormPostCommand;
  return formClient.post(command);
};
