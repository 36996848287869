import React, { useContext, useEffect } from 'react';
import {
  mergeStyles,
} from '@fluentui/react';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { SuccessPageHeroContent } from './successPageHeroContent';
import { FormContext } from '../../components/formProvider/formContext';
import { getFormattedDate } from '../../modules/datetime/datetime';

const successPageWrapperStyles = mergeStyles({
  marginTop: '20px',
  justifyContent: 'center',
  display: 'flex',
});

const successPageStyles = mergeStyles({
  maxWidth: '750px',
  fontSize: '1.25em',
});

const emailDetailsStyles = mergeStyles({
  marginLeft: '1em',
});

export const SuccessPage: React.FunctionComponent = () => {
  const heroContentContext = useContext(HeroContentContext);
  const formContext = useContext(FormContext);

  useEffect(() => {
    heroContentContext.setShowHero(true);
    heroContentContext.setHeroContent(SuccessPageHeroContent);
    heroContentContext.setHeroTitle('Thank you');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailAddress = 'us_gvs_leadership@microsoft.com';
  const name = `${formContext.formData.organizationContactInfo?.firstName} ${formContext.formData.organizationContactInfo?.lastName}`;
  const dateSubmitted = getFormattedDate(new Date());
  const companyName = formContext.formData.organizationInfo?.name;
  const subject = `Escalation Request for ${companyName}`;
  const body = `Company Name: ${companyName}%0D%0ADate Request Submitted: ${dateSubmitted}%0D%0APOC: ${name}%0D%0ABusiness Justification:`;
  const href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;

  return (
    <div className={successPageWrapperStyles}>
      <div className={successPageStyles}>
        {`Hi ${name},`}
        <br />
        <br />
        We&apos;ve received your request. Our SLA is 1 - 3 business days. Please monitor your email for any additional requests or updates on your request.
        <br />
        <br />
        For escalations please send an email to&nbsp;
        <a href={href}>
          {emailAddress}
        </a>
        &nbsp;with the following:
        <br />
        <br />
        <div className={emailDetailsStyles}>
          {`Subject line: ${subject}`}
          <br />
          {`Company Name: ${companyName}`}
          <br />
          {`Date Request Submitted: ${dateSubmitted}`}
          <br />
          {`POC: ${name}`}
          <br />
          Business Justification:
        </div>
        <br />
        Thanks,
        <br />
        <br />
        Government Validation Team
        <br />
        <br />
        For validation eligibility questions or status inqueries
        <b> only</b>
        , please email&nbsp;
        <a href="mailto:USGCCE@microsoft.com">USGCCE@microsoft.com</a>
        .
        <br />
        <br />
        For all other inquiries (pricing, products, functionality, etc.), please utilize the following resources:
        <br />
        <a href="https://azure.microsoft.com/en-us/offers/azure-government/?v=17.23h" target="_blank" rel="noopener noreferrer">Azure Government - How to Buy</a>
        <br />
        <a href="https://docs.microsoft.com/en-us/office365/servicedescriptions/office-365-platform-service-description/office-365-us-government/microsoft-365-government-how-to-buy" target="_blank" rel="noopener noreferrer">M365 - How to Buy</a>
        <br />
        <br />
        Please provide feedback on your intake form experience:
        <br />
        <a href="https://aka.ms/US_Government_Cloud_Eligibility_Intake_Survey">https://aka.ms/US_Government_Cloud_Eligibility_Intake_Survey</a>
      </div>
    </div>
  );
};
