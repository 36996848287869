export const getFormattedDate = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;
};

export const getFormattedDateTime = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()} ${formattedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
};

export const getChartFormattedDate = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.toLocaleString('default', { month: 'short' })} ${formattedDate.getFullYear()}`;
};

export const formatDateInternationalStandard = (date?: Date | null | undefined): string => (date ? date.toISOString().substring(0, 10) : '');
