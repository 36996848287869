import { ApplicationVersionInfo } from '../../models/applicationVersionInfo';
import { logError } from '../logging/logging';

let applicationVersionInfo: ApplicationVersionInfo;

export const initializeApplicationInfo = async (): Promise<void> => {
  try {
    const infoData = await fetch('/api/app-info');
    applicationVersionInfo = await infoData.json();
  } catch (error) {
    logError('An error occurred while initializing the application info', error);
  }
};

export const getApplicationVersion = (): ApplicationVersionInfo => applicationVersionInfo;
