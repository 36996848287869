import {
  mergeStyles,
  Pivot,
  PivotItem,
  IStyleSet,
  IPivotStyles,
  mergeStyleSets,
  IStyle,
} from '@fluentui/react';
import React, {
  FunctionComponent, ReactNode, useEffect, useMemo,
} from 'react';

const tabStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
} as IStyle;

const pivotItemStyle = mergeStyles({
  display: 'flex',
  flex: 1,
});

const bodyStyle = mergeStyles({
  overflow: 'auto',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
});

const pivotStyles: Partial<IStyleSet<IPivotStyles>> = {
  itemContainer: {
    flex: '1',
    display: 'flex',
  },
};

const hideTabsStyle = mergeStyles({
  selectors: {
    'div[role=tablist]': {
      display: 'none',
    },
  },
});

export interface TabProps {
  children?: ReactNode,
  className?: string,
  activePage?: number,
}

export const Tab: FunctionComponent<TabProps> = (props) => {
  const {
    className,
    activePage,
  } = props;
  const children = React.Children.toArray(props.children);
  const [selectedPageKey, setSelectedPageKey] = React.useState(activePage);

  useEffect(() => {
    setSelectedPageKey(activePage);
  }, [activePage]);

  const classNames = useMemo(() => mergeStyleSets({
    root: [
      className,
      tabStyle,
      children.length <= 1 ? hideTabsStyle : {},
    ],
  }), [className, children.length]);

  return (
    <div className={classNames.root}>
      <Pivot className={bodyStyle} selectedKey={String(selectedPageKey)} styles={pivotStyles}>
        {
          children.map((child, index) => (
            React.isValidElement(child) && (
              <PivotItem itemIcon={child.props.icon} key={child.props.title} itemKey={index.toString()} headerText={child.props.title} className={pivotItemStyle}>
                {child}
              </PivotItem>
            )
          ))
        }
      </Pivot>
    </div>
  );
};
