import { keyframes, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const animatedEllipsesAnimation = keyframes({
  '0%': {
    transform: 'scale(1)',
    opacity: 0.3,
  },
  '36.1%': {
    transform: 'scale(1.06)',
    opacity: 1,
  },
  '50%': {
    transform: 'scale(1.06)',
    opacity: 1,
  },
  '86.1%': {
    transform: 'scale(1)',
    opacity: 0.3,
  },
});

const animatedEllipsesShadowAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '36.1%': {
    opacity: 0.4,
  },
  '50%': {
    opacity: 0.4,
  },
  '86.1%': {
    opacity: 0,
  },
});

const progressDotStyle = mergeStyles({
  display: 'inline-block',
  height: '10px',
  width: '10px',
  animation: `${animatedEllipsesAnimation} 1.8s infinite`,
  backgroundColor: '#3471ff',
  margin: '0 5px',
  borderRadius: '5px',
  opacity: '.3',
  position: 'relative',
  selectors: {
    '::after': {
      content: '',
      position: 'absolute',
      left: 0,
      borderRadius: '5px',
      width: '100%',
      height: '100%',
      opacity: 0,
      boxShadow: '0 3px 3px 0 #0078d4',
      animation: `${animatedEllipsesShadowAnimation} 1.8s infinite`,
    },
    ':nth-child(1)': {
      animationDelay: '0s',
    },
    ':nth-child(2)': {
      animationDelay: '.125s',
    },
    ':nth-child(3)': {
      animationDelay: '.25s',
    },
  },
});

const progressDotInnerStyle = mergeStyles({
  content: '',
  position: 'absolute',
  left: 0,
  borderRadius: '5px',
  width: '100%',
  height: '100%',
  opacity: 0,
  boxShadow: '0 3px 3px 0 #0078d4',
  animation: `${animatedEllipsesShadowAnimation} 1.8s infinite`,
  selectors: {
    ':nth-child(1)': {
      animationDelay: '0s',
    },
    ':nth-child(2)': {
      animationDelay: '.125s',
    },
    ':nth-child(3)': {
      animationDelay: '.25s',
    },
  },
});

export const ProgressDots: FunctionComponent = () => (
  <div>
    <div className={progressDotStyle}><div className={progressDotInnerStyle} /></div>
    <div className={progressDotStyle}><div className={progressDotInnerStyle} /></div>
    <div className={progressDotStyle}><div className={progressDotInnerStyle} /></div>
  </div>
);

const centeredProgressDotsStyle = mergeStyles({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const centeredProgressDotsInnerStyle = mergeStyles({
  position: 'absolute',
  top: 'calc(50% - 10px/2)',
  width: '100%',
  lineHeight: 0,
  textAlign: 'center',
});

export const CenteredProgressDots: FunctionComponent = () => (
  <div className={centeredProgressDotsStyle}>
    <div className={centeredProgressDotsInnerStyle}>
      <ProgressDots />
    </div>
  </div>
);
