import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const tabPageStyles = mergeStyles({
  margin: '10px',
  display: 'flex',
  flex: 1,
});

const tabPageNoMarginStyles = mergeStyles({
  display: 'flex',
  flex: 1,
});

export interface TabPageProps {
  title: string,
  icon: string,
  noMargin?: boolean,
  children?: JSX.Element,
}

export const TabPage: FunctionComponent<TabPageProps> = (props) => (
  <div className={props.noMargin ? tabPageNoMarginStyles : tabPageStyles}>
    {props.children}
  </div>
);
