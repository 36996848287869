import { mergeStyles } from '@fluentui/react';
import { Tab } from 'components/tab/tab';
import { TabPage } from 'components/tab/tabPage';
import { FormToolTab } from 'models/formToolTab';
import { CategoryTab } from 'pages/categoryTab/categoryTab';
import { OrganizationTab } from 'pages/organizationTab/organizationTab';
import { SupportingTab } from 'pages/suppportingTab/supportingTab';
import React, { useContext, useEffect, useState } from 'react';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { LandingPageHeroContent } from './landingPageHeroContent';

const tabStyle = mergeStyles({
  height: '100%',
});

export const LandingPage: React.FunctionComponent = () => {
  const heroContentContext = useContext(HeroContentContext);
  const [activeTabPage, setActiveTabPage] = useState<number>(FormToolTab.Category);

  useEffect(() => {
    heroContentContext.setShowHero(true);
    heroContentContext.setHeroContent(LandingPageHeroContent);
    heroContentContext.setHeroTitle('Tell us about yourself and organization');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIcon = (tab : FormToolTab) => {
    let icon = '';
    switch (tab) {
      case FormToolTab.Category:
        if (activeTabPage === FormToolTab.Category) {
          icon = 'CircleOneSelectedIcon';
        } else {
          icon = 'CircleSuccessIcon';
        }
        break;
      case FormToolTab.OrganizationInfo:
        if (activeTabPage === FormToolTab.OrganizationInfo) {
          icon = 'CircleTwoSelectedIcon';
        } else if (activeTabPage > FormToolTab.OrganizationInfo) {
          icon = 'CircleSuccessIcon';
        } else {
          icon = 'CircleTwoIcon';
        }
        break;
      case FormToolTab.SupportInfo:
        if (activeTabPage === FormToolTab.SupportInfo) {
          icon = 'CircleThreeSelectedIcon';
        } else {
          icon = 'CircleThreeIcon';
        }
        break;
      default:
    }
    return icon;
  };

  return (
    <div>
      <Tab className={tabStyle} activePage={activeTabPage}>
        <TabPage title="Category" icon={getIcon(FormToolTab.Category)}>
          <CategoryTab changeActiveTab={setActiveTabPage} />
        </TabPage>
        <TabPage title="Organization & contact information" icon={getIcon(FormToolTab.OrganizationInfo)}>
          <OrganizationTab changeActiveTab={setActiveTabPage} />
        </TabPage>
        <TabPage title="Supporting information" icon={getIcon(FormToolTab.SupportInfo)}>
          <SupportingTab changeActiveTab={setActiveTabPage} />
        </TabPage>
      </Tab>
    </div>
  );
};
