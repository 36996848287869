import React, { FunctionComponent, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { HeroContentContext } from './heroContentContext';

export const HeroContentProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [heroContent, setHeroContent] = useState<React.ReactNode>(<></>);
  const [showHeroContent, setShowHeroContent] = useState<boolean>(false);
  const [heroTitle, setHeroTitle] = useState<string>('');

  const contextProps = {
    setHeroContent: (content: React.ReactNode) => setHeroContent(content),
    getHeroContent: (): React.ReactNode => heroContent,
    setShowHero: (showHero: boolean) => setShowHeroContent(showHero),
    getShowHero: () => showHeroContent,
    setHeroTitle: (heroTitle: string) => setHeroTitle(heroTitle),
    getHeroTitle: () => heroTitle,
  };

  return (
    <HeroContentContext.Provider value={contextProps}>{children}</HeroContentContext.Provider>
  );
};
