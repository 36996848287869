import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  mergeStyles, Stack, TextField,
} from '@fluentui/react';
import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { useId } from '@fluentui/react-hooks';
import { FormContext } from 'components/formProvider/formContext';
import { SaveButton } from 'components/saving/saveButton';
import { FormToolTab } from 'models/formToolTab';
import _ from 'lodash';
import { LabelWithToolTip } from 'components/labelWithTooltip/labelWithTooltip';
import { LoadingState } from 'models/loadingState';

const formStyle = mergeStyles({
  marginTop: '2em',
  maxWidth: '600px',
});

const buttonStyle = mergeStyles({
  marginTop: '3em',
});

const labelStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '16px',
});

const largeTextFieldStyles = mergeStyles({
  width: 600,
  marginTop: '2em',
  label: {
    fontWeight: 600,
  },
});

const normalTextFieldStyles = mergeStyles({
  width: 290,
  label: {
    fontWeight: 600,
  },
});

const normalTextFieldLabelStyles = mergeStyles({
  width: 'auto',
  fontWeight: 600,
});

const smallTextFieldStyles = mergeStyles({
  width: 190,
  label: {
    fontWeight: 600,
  },
});

// eslint-disable-next-line no-useless-escape
const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const isValidEmail = (email? : string) => email?.match(emailRegex) !== null;

export interface OrganizationTabProps {
      changeActiveTab(targetTab: number): void
  }

export const OrganizationTab: FunctionComponent<OrganizationTabProps> = (props) => {
  const { changeActiveTab } = props;

  const formContext = useContext(FormContext);
  const [regionOptions, setRegionOptions] = useState<IDropdownOption[]>([]);
  const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);
  const [selectedRegionId, setSelectedRegionId] = useState<string>();

  const [organizationName, setOrganizationName] = useState<string | undefined>(formContext.formData.organizationInfo?.name);
  const [organizationNameErrorMessage, setOrganizationNameErrorMessage] = useState<string | undefined>();
  const [website, setWebsite] = useState<string | undefined>(formContext.formData.organizationInfo?.website);
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState<string | undefined>();
  const [countryRegion, setCountryRegion] = useState<string | undefined>(formContext.formData.organizationInfo?.countryRegion);
  const [address, setAddress] = useState<string | undefined>(formContext.formData.organizationInfo?.address);
  const [addressErrorMessage, setAddressErrorMessage] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>(formContext.formData.organizationInfo?.city);
  const [cityErrorMessage, setCityErrorMessage] = useState<string | undefined>();
  const [suite, setSuite] = useState<string | undefined>(formContext.formData.organizationInfo?.suite);
  const [suiteErrorMessage, setSuiteErrorMessage] = useState<string | undefined>();
  const [stateProvince, setStateProvince] = useState<string | undefined>(formContext.formData.organizationInfo?.stateProvince);
  const [postalCode, setPostalCode] = useState<string | undefined>(formContext.formData.organizationInfo?.postalCode);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string | undefined>();
  const [contactFirstName, setContactFirstName] = useState<string | undefined>(formContext.formData.organizationContactInfo?.firstName);
  const [contactFirstNameErrorMessage, setContactFirstNameErrorMessage] = useState<string | undefined>();
  const [contactLastName, setContactLastName] = useState<string | undefined>(formContext.formData.organizationContactInfo?.lastName);
  const [contactLastNameErrorMessage, setContactLastNameErrorMessage] = useState<string | undefined>();
  const [contactEmailAddress, setContactEmailAddress] = useState<string | undefined>(formContext.formData.organizationContactInfo?.emailAddress);
  const [contactConfirmEmailAddress, setContactConfirmEmailAddress] = useState<string | undefined>();
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
  const [contactAreaCode, setContactAreaCode] = useState<string | undefined>(formContext.formData.organizationContactInfo?.areaCodePrefix);
  const [contactAreaCodeErrorMessage, setContactAreaCodeErrorMessage] = useState<string | undefined>();
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string | undefined>(formContext.formData.organizationContactInfo?.phoneNumber);
  const [contactPhoneNumberErrorMessage, setContactPhoneNumberErrorMessage] = useState<string | undefined>();
  const [contactExtension, setContactExtension] = useState<string | undefined>(formContext.formData.organizationContactInfo?.extension);
  const [contactExtensionErrorMessage, setContactExtensionErrorMessage] = useState<string | undefined>();
  const [buttonDisabled, setbuttonDisabled] = useState<boolean>(true);

  const textBoxId: string = useId('textBox-');

  const stackTokens = { childrenGap: 10 };
  const horizontalStackTokens = { childrenGap: 20 };

  useEffect(() => {
    let regions : IDropdownOption[] = [];

    // top regions (US, US Outlying Islands, US Virgin Islands)
    const topRegionIds = ['95cf73bb-bb5d-e611-80dc-c4346bac03fc', '89cf73bb-bb5d-e611-80dc-c4346bac03fc', '8bcf73bb-bb5d-e611-80dc-c4346bac03fc'];
    const topRegions: IDropdownOption[] = [];

    formContext.countryRegions?.forEach((region) => {
      if (topRegionIds.includes(region.countryRegionID as string)) {
        topRegions.push({ key: region.countryRegionID as string, text: region.countryRegionName as string });
      } else {
        regions.push({ key: region.countryRegionID as string, text: region.countryRegionName as string });
      }
      if (countryRegion === region.countryRegionName) {
        setSelectedRegionId(region.countryRegionID);
      }
    });

    // put top regions at the beginning of the array
    if (topRegions.length > 0) {
      regions = [...topRegions, ...regions];
    }

    setRegionOptions(regions);
  }, [countryRegion, formContext.countryRegions]);

  useEffect(() => {
    const stateOptions: IDropdownOption[] = [];

    // Make sure the states list is loaded first
    if (formContext.stateProvinceListLoaded !== LoadingState.Loaded) {
      return;
    }

    // If the user chose a country that has no states associated with it, set the state as the country too
    if (formContext.stateProvinceList?.length === 0 && countryRegion) {
      stateOptions.push({ key: countryRegion as string, text: countryRegion as string });
      setStateOptions(stateOptions);
      setStateProvince(countryRegion);
      return;
    }

    formContext.stateProvinceList?.forEach((state) => {
      stateOptions.push({ key: state.stateProvinceName as string, text: state.stateProvinceName as string });
    });
    setStateOptions(stateOptions);
  }, [formContext.stateProvinceList, formContext.stateProvinceListLoaded, countryRegion]);

  useEffect(() => {
    if (isNullOrEmpty(organizationName) || isNullOrEmpty(website) || isNullOrEmpty(countryRegion)
    || isNullOrEmpty(address) || isNullOrEmpty(stateProvince) || isNullOrEmpty(postalCode) || isNullOrEmpty(city)
    || isNullOrEmpty(contactFirstName) || isNullOrEmpty(contactLastName) || isNullOrEmpty(contactEmailAddress) || isNullOrEmpty(contactConfirmEmailAddress)
    || isNullOrEmpty(contactAreaCode) || isNullOrEmpty(contactPhoneNumber)
    || !isNullOrEmpty(organizationNameErrorMessage) || !isNullOrEmpty(websiteErrorMessage) || !isNullOrEmpty(addressErrorMessage)
    || !isNullOrEmpty(cityErrorMessage) || !isNullOrEmpty(postalCodeErrorMessage) || !isNullOrEmpty(emailErrorMessage)
    || !isNullOrEmpty(contactFirstNameErrorMessage) || !isNullOrEmpty(contactLastNameErrorMessage) || !isNullOrEmpty(contactAreaCodeErrorMessage)
    || !isNullOrEmpty(contactPhoneNumberErrorMessage) || !isNullOrEmpty(contactExtensionErrorMessage) || !isNullOrEmpty(suiteErrorMessage)
    || (contactEmailAddress !== contactConfirmEmailAddress) || !isValidEmail(contactEmailAddress)) {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [address, city, contactAreaCode, contactConfirmEmailAddress, contactEmailAddress, contactFirstName, contactLastName, contactPhoneNumber, countryRegion, organizationName, postalCode, stateProvince, website, organizationNameErrorMessage, websiteErrorMessage, addressErrorMessage, cityErrorMessage, postalCodeErrorMessage, emailErrorMessage, contactFirstNameErrorMessage, contactLastNameErrorMessage, contactAreaCodeErrorMessage, contactPhoneNumberErrorMessage, contactExtensionErrorMessage, suiteErrorMessage]);

  const isNullOrEmpty = (str? : string) => str === undefined || str.length === 0;

  function validateInputForInvalidCharacters(value: string | undefined) {
    const invalidCharRegex = /[<>]/; // a regular expression that matches < or >
    if (value && value.match(invalidCharRegex)) {
      return 'Characters `<` and `>` are not allowed';
    }
    return '';
  }

  const saveData = () => {
    formContext.updateOrganizationInfo({
      name: organizationName, website, countryRegion, address, city, suite, stateProvince, postalCode,
    });

    formContext.updateOrganizationContactInfo({
      firstName: contactFirstName, lastName: contactLastName, emailAddress: contactEmailAddress, areaCodePrefix: contactAreaCode, phoneNumber: contactPhoneNumber, extension: contactExtension,
    });
  };

  const onRegionSelected = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    setCountryRegion(option?.text);
    setStateProvince('');
    formContext.fetchStates(option?.key as string);
  };

  const onNextClicked = () => {
    saveData();
    changeActiveTab(FormToolTab.SupportInfo);
  };

  const onPreviousClicked = () => {
    saveData();
    changeActiveTab(FormToolTab.Category);
  };

  const getTextBoxId = (label: string): string => `${textBoxId}-${_.kebabCase(label)}`;

  useEffect(() => {
    setOrganizationNameErrorMessage(validateInputForInvalidCharacters(organizationName));
  }, [organizationName]);

  useEffect(() => {
    setWebsiteErrorMessage(validateInputForInvalidCharacters(website));
  }, [website]);

  useEffect(() => {
    setAddressErrorMessage(validateInputForInvalidCharacters(address));
  }, [address]);

  useEffect(() => {
    setCityErrorMessage(validateInputForInvalidCharacters(city));
  }, [city]);

  useEffect(() => {
    setSuiteErrorMessage(validateInputForInvalidCharacters(suite));
  }, [suite]);

  useEffect(() => {
    setPostalCodeErrorMessage(validateInputForInvalidCharacters(postalCode));
  }, [postalCode]);

  useEffect(() => {
    if (contactConfirmEmailAddress && (contactEmailAddress !== contactConfirmEmailAddress)) {
      setEmailErrorMessage('Email entries must match');
    } else if (!isValidEmail(contactEmailAddress)) {
      setEmailErrorMessage('Must be a valid email address');
    } else {
      setEmailErrorMessage(validateInputForInvalidCharacters(contactEmailAddress));
    }
  }, [contactConfirmEmailAddress, contactEmailAddress]);

  useEffect(() => {
    if (contactFirstName && contactFirstName?.length >= 20) {
      setContactFirstNameErrorMessage('First name cannot exceed 20 characters');
    } else {
      setContactFirstNameErrorMessage(validateInputForInvalidCharacters(contactFirstName));
    }
  }, [contactFirstName]);

  useEffect(() => {
    if (contactLastName && contactLastName?.length >= 20) {
      setContactLastNameErrorMessage('Last name cannot exceed 20 characters');
    } else {
      setContactLastNameErrorMessage(validateInputForInvalidCharacters(contactLastName));
    }
  }, [contactLastName]);

  useEffect(() => {
    setContactAreaCodeErrorMessage(validateInputForInvalidCharacters(contactAreaCode));
  }, [contactAreaCode]);

  useEffect(() => {
    setContactPhoneNumberErrorMessage(validateInputForInvalidCharacters(contactPhoneNumber));
  }, [contactPhoneNumber]);

  useEffect(() => {
    setContactExtensionErrorMessage(validateInputForInvalidCharacters(contactExtension));
  }, [contactExtension]);

  return (
    <div className={formStyle}>
      <h4 style={{ marginBlockEnd: 0, marginBlockStart: 0 }}>
        <LabelWithToolTip
          id="label-org-info"
          label="Organization Information"
          className={labelStyles}
          tooltipProps={{
            onRenderContent: () => (
              <ul>
                <li>This must be the actual company being validated; a parent or subsidiary company cannot submit on their behalf.</li>
                <li>This must be a US based company location and a US based requestor.</li>
                <li>This must be the entity on the government contract or holding specific US Government controlled data.</li>
              </ul>
            ),
          }}
        />
      </h4>
      <Stack tokens={stackTokens}>
        <TextField
          id={getTextBoxId('Organization name')}
          label="Organization legal name and any DBA"
          placeholder="Enter organization name"
          required
          className={largeTextFieldStyles}
          defaultValue={organizationName}
          onChange={(_event, input) => { setOrganizationName(input); }}
          errorMessage={organizationNameErrorMessage}
        />
        <Stack horizontal tokens={horizontalStackTokens}>
          <TextField id={getTextBoxId('Organization website')} label="Organization website" placeholder="Enter organization URL" required className={normalTextFieldStyles} defaultValue={website} onChange={(_event, input) => { setWebsite(input); }} errorMessage={websiteErrorMessage} />
          <Dropdown
            id="dropdown-country"
            required
            placeholder="Select an option"
            label="Country/Region"
            options={regionOptions}
            className={normalTextFieldStyles}
            defaultSelectedKey={selectedRegionId}
            onChange={onRegionSelected}
          />
        </Stack>
        <TextField
          id={getTextBoxId('Organization address')}
          placeholder="Enter organization address"
          className={largeTextFieldStyles}
          defaultValue={address}
          onChange={(_event, input) => { setAddress(input); }}
          ariaLabel="Organization physical street address"
          onRenderLabel={() => (
            <LabelWithToolTip
              label="Organization physical street address"
              showRequired
              toolTipText="This must be a valid physical street address. A PO Box cannot be used."
              className={normalTextFieldLabelStyles}
            />
          )}
          errorMessage={addressErrorMessage}
        />
        <Stack horizontal tokens={horizontalStackTokens}>
          <TextField id={getTextBoxId('Organization city')} label="City" placeholder="Enter city" required className={normalTextFieldStyles} defaultValue={city} onChange={(_event, input) => { setCity(input); }} errorMessage={cityErrorMessage} />
          <TextField id={getTextBoxId('Organization suite')} label="Suite" placeholder="Enter suite number" className={normalTextFieldStyles} defaultValue={suite} onChange={(_event, input) => { setSuite(input); }} errorMessage={suiteErrorMessage} />
        </Stack>
        <Stack horizontal tokens={horizontalStackTokens}>
          <Dropdown
            id="dropdown-state"
            required
            placeholder="Select an option"
            label="State"
            options={stateOptions}
            className={normalTextFieldStyles}
            defaultSelectedKey={stateProvince}
            onChange={(_event, item) => { setStateProvince(item?.key as string); }}
          />
          <TextField id={getTextBoxId('Organization zip code')} label="Postal code" placeholder="Enter zip code" required className={normalTextFieldStyles} defaultValue={postalCode} onChange={(_event, input) => { setPostalCode(input); }} errorMessage={postalCodeErrorMessage} />
        </Stack>

        <div style={{ borderTop: '2px solid #EFEFEF ', minWidth: 600, marginTop: '3em' }} />

        <h4 style={{ marginTop: '3em', marginBlockEnd: 0 }}>
          <LabelWithToolTip
            label="Organization Contact Person Information"
            className={labelStyles}
            tooltipProps={{
              onRenderContent: () => (
                <ul>
                  <li>This must be a contact at the entity seeking validation and must have the authority to review and accept the Microsoft Terms and Conditions.</li>
                  <li>This must be a US based requestor.</li>
                </ul>
              ),
            }}
          />
        </h4>

        <Stack horizontal tokens={horizontalStackTokens}>
          <TextField
            id={getTextBoxId('First Name')}
            placeholder="Enter contact first name"
            className={normalTextFieldStyles}
            defaultValue={contactFirstName}
            onChange={(_event, input) => { setContactFirstName(input); }}
            ariaLabel="Organization contact first name"
            onRenderLabel={() => (
              <LabelWithToolTip
                label="Organization contact first name"
                showRequired
                toolTipText="This must be a valid contact first name"
                className={normalTextFieldLabelStyles}
              />
            )}
            errorMessage={contactFirstNameErrorMessage}
          />
          <TextField
            id={getTextBoxId('Last Name')}
            placeholder="Enter contact last name"
            className={normalTextFieldStyles}
            defaultValue={contactLastName}
            onChange={(_event, input) => { setContactLastName(input); }}
            ariaLabel="Organization contact last name"
            onRenderLabel={() => (
              <LabelWithToolTip
                label="Organization contact last name"
                showRequired
                toolTipText="This must be a valid contact last name"
                className={normalTextFieldLabelStyles}
              />
            )}
            errorMessage={contactLastNameErrorMessage}
          />
        </Stack>

        <Stack horizontal tokens={horizontalStackTokens}>
          <TextField id={getTextBoxId('Email address')} label="Organization contact email address" placeholder="Enter email address" required className={normalTextFieldStyles} defaultValue={contactEmailAddress} onChange={(_event, input) => { setContactEmailAddress(input); }} />
          <TextField
            id={getTextBoxId('Email address verify')}
            label="Confirm email address"
            placeholder="Re-enter email address"
            errorMessage={emailErrorMessage}
            required
            className={normalTextFieldStyles}
            defaultValue={contactConfirmEmailAddress}
            onChange={(_event, input) => {
              setContactConfirmEmailAddress(input);
            }}
          />
        </Stack>

        <Stack horizontal tokens={horizontalStackTokens}>
          <TextField id={getTextBoxId('Area code')} label="Area Code/Prefix" placeholder="Enter area code" required className={smallTextFieldStyles} defaultValue={contactAreaCode} onChange={(_event, input) => { setContactAreaCode(input); }} errorMessage={contactAreaCodeErrorMessage} />
          <TextField id={getTextBoxId('Phone number')} label="Phone number" placeholder="Enter phone number" required className={smallTextFieldStyles} defaultValue={contactPhoneNumber} onChange={(_event, input) => { setContactPhoneNumber(input); }} errorMessage={contactPhoneNumberErrorMessage} />
          <TextField id={getTextBoxId('Phone extension')} label="Extension" placeholder="Enter extension" className={smallTextFieldStyles} defaultValue={contactExtension} onChange={(_event, input) => { setContactExtension(input); }} errorMessage={contactExtensionErrorMessage} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={horizontalStackTokens}>
        <SaveButton className={buttonStyle} defaultText="Next" onSave={onNextClicked} disabled={buttonDisabled} />
        <DefaultButton text="Previous" onClick={onPreviousClicked} className={buttonStyle} />
      </Stack>
    </div>
  );
};
