import { ServerConfig } from '../../models/serverConfig';

let config: ServerConfig;

export const initializeConfig = async (): Promise<void> => {
  try {
    const configData = await fetch('/api/config');
    const serverConfig = await configData.json();
    config = serverConfig;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Configurations failed to initialize', error);
    throw error;
  }
};

export const getConfig = (): ServerConfig => config;
