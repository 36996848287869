import { AzureThemeLight } from '@fluentui/azure-themes';
import { mergeStyles, ThemeProvider } from '@fluentui/react';
import { FormProvider } from 'components/formProvider/formProvider';
import { Hero } from 'components/hero/hero';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ConfigProvider } from './components/configProvider/configProvider';
import { Footer } from './components/footer/footer';
import { HeroContentProvider } from './components/heroContentProvider/heroContentProvider';
import { PageChromeHost } from './components/pageChrome/pageChrome';
import { CenteredProgressDots } from './components/progressDots/progressDots';
import { LoadingState } from './models/loadingState';
import { getApplicationVersion, initializeApplicationInfo } from './modules/config/appInfo';
import { initializeConfig } from './modules/config/config';
import { getFailedToLoadUi } from './modules/loading/loading';
import { initializeLogging, logError } from './modules/logging/logging';
import {
  landingRoute,
  successRoute,
} from './modules/routes/routes';
import { LandingPage } from './pages/landing/landingPage';
import { SuccessPage } from './pages/success/success';

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html)': {
      height: '100%',
    },
    ':global(body)': {
      margin: 0,
      padding: 0,
    },
    ':global(#root)': {
      minHeight: '100vh',
      minWidth: '650px',
    },
  },
});

const themeProviderStyle = mergeStyles({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const browserHistory = createBrowserHistory();

export const App = (): React.ReactElement => {
  const [appViewState, setAppViewState] = useState(LoadingState.NotLoaded);

  useEffect(() => {
    const redirectUri = sessionStorage.getItem('redirectUri');
    if (!redirectUri) {
      sessionStorage.setItem('redirectUri', window.location.href);
    }
    const initialize = async () => {
      try {
        await initializeConfig();
        await initializeApplicationInfo();
        initializeLogging();

        setAppViewState(LoadingState.Loaded);
      } catch (error) {
        setAppViewState(LoadingState.Error);
        logError('Configurations failed to initialize', error);
      }
    };

    initialize();
  }, []);

  const getLoadedUi = () => (
    <Switch>
      <Route exact path={landingRoute()}>
        <FormProvider>
          <LandingPage />
        </FormProvider>
      </Route>
      <Route exact path={successRoute()}>
        <FormProvider>
          <SuccessPage />
        </FormProvider>
      </Route>
    </Switch>
  );

  const getUiFromState = () => {
    switch (appViewState) {
      case LoadingState.Loaded:
        return getLoadedUi();
      case LoadingState.Error:
        return getFailedToLoadUi();
      case LoadingState.NotLoaded:
      case LoadingState.Loading:
      default:
        return <CenteredProgressDots />;
    }
  };

  return (
    <ThemeProvider className={themeProviderStyle} theme={AzureThemeLight}>
      <Router history={browserHistory}>
        <ConfigProvider>
          <HeroContentProvider>
            <Hero />
            <PageChromeHost>
              <section role="main">
                {getUiFromState()}
              </section>
            </PageChromeHost>
          </HeroContentProvider>
          <Footer applicationVersion={getApplicationVersion()} />
        </ConfigProvider>
      </Router>
    </ThemeProvider>
  );
};
