export const enum BusinessAssociations{
    BUSINESS_DEV = '8(a) Business',
    HUBZONE = 'HUBZone',
    WOMEN_OWNED = 'Women-Owned Small Business (WOSB)',
    VETERAN_ASSISTANCE = 'Service-Disabled Veteran-Owned Small Business (SDVOSB)',
    MINORITY_OWNED = 'Minority-Owned Business (this includes LGBTQ)',
    NAICS_CODE = 'NAICS Code',
    DISADVANTAGE = 'Small Disadvantaged Business',
    NATIVE_AMERICAN = 'Native American-Owned Business',
    NATIVE_AMERICAN_NHO = 'Native Hawaiian Organization (NHO)',
    NATIVE_AMERICAN_ALASKA_REGIONAL = 'Alaska Regional Native Corporation',
    NATIVE_AMERICAN_CORP = 'Native American Corporation',
}
