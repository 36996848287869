export const enum DataRegulation{
    CJI = 'CJI / CJIS: Criminal Justice Information',
    CUI = 'CUI: Controlled Unclassified Information',
    DFARS = 'DFARS: Defense Federal Acquisition Regulation Supplement',
    DOD = 'DoD UCNI: Department of Defense Unclassified Controlled Nuclear Information',
    DOE = 'DoE UCNI: Department of Energy Unclassified Controlled Nuclear Information',
    IRS = 'IRS 1075: Safeguards for Protecting Federal Tax Returns and Return Information',
    ITAR = 'ITAR: International Traffic in Arms Regulations',
    NERC = 'NERC: North American Electric Reliability Corporation',
}
