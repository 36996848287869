import {
  ITooltipProps, mergeStyles, Stack, TextField,
} from '@fluentui/react';
import { LabelWithToolTip } from 'components/labelWithTooltip/labelWithTooltip';
import React, { FunctionComponent } from 'react';

const labelStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '16px',
  width: 'fit-content',
});

const normalTextFieldStyles = mergeStyles({
  width: 290,
  label: {
    fontWeight: 600,
  },
});

export interface CategorySectionProps {
    key? : string,
    title? : string,
    subTitle?: string,
    toolTipText?: string,
    tooltipProps?: ITooltipProps,
    categoryInputOneLabel?: string,
    categoryInputOnePlaceholder?: string,
    categoryInputOneErrorMessage? : string,
    categoryInputOneDefaultValue?: string,
    categoryInputOneToolTip?: string,
    categoryInputOneRequired?: boolean,
    onCategoryInputOneChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void,
    categoryInputTwoLabel?: string,
    categoryInputTwoPlaceholder?: string,
    categoryInputTwoDefaultValue?: string,
    categoryInputTwoToolTip?: string,
    categoryInputTwoRequired?: boolean,
    onCategoryInputTwoChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void,
}

export const CategorySection : FunctionComponent<CategorySectionProps> = (props) => {
  const {
    key,
    title,
    subTitle,
    toolTipText,
    tooltipProps,
    categoryInputOneLabel,
    categoryInputOnePlaceholder,
    categoryInputOneErrorMessage,
    categoryInputOneDefaultValue,
    categoryInputOneToolTip,
    categoryInputOneRequired,
    onCategoryInputOneChange,
    categoryInputTwoLabel,
    categoryInputTwoPlaceholder,
    categoryInputTwoDefaultValue,
    categoryInputTwoToolTip,
    categoryInputTwoRequired,
    onCategoryInputTwoChange,
  } = props;

  const stackTokens = { childrenGap: 10 };
  const horizontalStackTokens = { childrenGap: 20 };

  return (
    <Stack tokens={stackTokens}>
      <div style={{ width: 'fit-content' }}>
        <LabelWithToolTip
          key={key}
          toolTipText={toolTipText}
          tooltipProps={tooltipProps}
          label={title}
          className={labelStyles}
        />
      </div>
      <div>{subTitle}</div>
      <Stack horizontal tokens={horizontalStackTokens}>
        {categoryInputOneLabel
        && (

        <TextField
          label={categoryInputOneLabel}
          placeholder={categoryInputOnePlaceholder}
          errorMessage={categoryInputOneErrorMessage}
          ariaLabel={categoryInputOneToolTip}
          required={categoryInputOneRequired}
          value={categoryInputOneDefaultValue}
          className={normalTextFieldStyles}
          onChange={onCategoryInputOneChange}
        />

        )}
        {categoryInputTwoLabel
        && (
        <TextField
          label={categoryInputTwoLabel}
          ariaLabel={categoryInputTwoToolTip}
          required={categoryInputTwoRequired}
          placeholder={categoryInputTwoPlaceholder}
          className={normalTextFieldStyles}
          value={categoryInputTwoDefaultValue}
          onChange={onCategoryInputTwoChange}
        />
        )}
      </Stack>
    </Stack>
  );
};
