import { mergeStyles, registerIcons } from '@fluentui/react';
import React from 'react';
import {
  AzureMenuS360OverviewIcon, CircleOneSelectedIcon, CircleSuccessIcon, CircleThreeIcon, CircleThreeSelectedIcon, CircleTwoIcon, CircleTwoSelectedIcon,
} from './icons';

export * from './icons';

const azureIconStyle = mergeStyles({
  width: '16px',
  height: '16px',
  verticalAlign: 'textTop',
});

export function registerAzureIcons(): void {
  registerIcons({
    icons: {
      AzureMenuS360Overview: <AzureMenuS360OverviewIcon className={azureIconStyle} />,
      CircleOneSelectedIcon: <CircleOneSelectedIcon className={azureIconStyle} />,
      CircleTwoSelectedIcon: <CircleTwoSelectedIcon className={azureIconStyle} />,
      CircleThreeSelectedIcon: <CircleThreeSelectedIcon className={azureIconStyle} />,
      CircleTwoIcon: <CircleTwoIcon className={azureIconStyle} />,
      CircleThreeIcon: <CircleThreeIcon className={azureIconStyle} />,
      CircleSuccessIcon: <CircleSuccessIcon className={azureIconStyle} />,

    },
  });
}
