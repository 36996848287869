import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { RouteProps } from 'react-router-dom';
import { LoadingState } from '../../models/loadingState';
import { getFailedToLoadUi } from '../../modules/loading/loading';
import { logError } from '../../modules/logging/logging';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ConfigContext, ConfigContextProps } from './configContext';
import { ServerConfig } from '../../models/serverConfig';
import { getConfig } from '../../modules/config/config';

export const ConfigProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [serverConfig, setServerConfig] = useState<ServerConfig>();

  const contextProps: ConfigContextProps = {
    serverConfig,
  };

  useEffect(() => {
    const getConfigData = async () => {
      try {
        setLoadingState(LoadingState.Loading);

        const [serverConfig] = await Promise.all([
          getConfig(),
        ]);

        setServerConfig(serverConfig);
        setLoadingState(LoadingState.Loaded);
      } catch (error) {
        setLoadingState(LoadingState.Error);
        logError('There was an issue loading the configuration data', error);
      }
    };

    getConfigData();
  }, []);

  const getUiFromState = () => {
    switch (loadingState) {
      case LoadingState.Loaded:
      case LoadingState.Loading:
        return <ConfigContext.Provider value={contextProps}>{children}</ConfigContext.Provider>;
      case LoadingState.Error:
        return getFailedToLoadUi();
      case LoadingState.NotLoaded:
      default:
        return <CenteredProgressDots />;
    }
  };

  return getUiFromState();
};
