import {
  Checkbox, ICheckboxProps, IRenderFunction, ITooltipProps,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { LabelWithToolTip } from 'components/labelWithTooltip/labelWithTooltip';
import React, { FunctionComponent } from 'react';

const closeDelayTime = 800;

export interface ICheckboxWithTooltipProps {
    toolTipText?: string,
    onRenderToolTip?: IRenderFunction<ITooltipProps>,
    checkBoxProps : ICheckboxProps,
}

export const CheckboxWithToolTip : FunctionComponent<ICheckboxWithTooltipProps> = (props) => {
  const labelId: string = useId('tooltip');
  const tooltipProps: ITooltipProps = {
    content: props.toolTipText,
    onRenderContent: props.onRenderToolTip,
  };

  const onRenderLabel = (checkProps: ICheckboxProps | undefined) => (
    <LabelWithToolTip
      id={labelId}
      toolTipText={props.toolTipText}
      tooltipProps={tooltipProps}
      closeDelay={closeDelayTime}
      label={checkProps?.label}
      className={checkProps?.className}
    />
  );

  return (
    <Checkbox
      id={props.checkBoxProps.id}
      checked={props.checkBoxProps.checked}
      label={props.checkBoxProps.label}
      ariaLabel={props.checkBoxProps.label}
      onRenderLabel={onRenderLabel}
      onChange={props.checkBoxProps.onChange}
    />
  );
};
