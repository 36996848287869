import {
  DirectionalHint, FontIcon, ITooltipProps, TooltipDelay, TooltipHost, mergeStyles,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { FunctionComponent } from 'react';

const labelStyles = mergeStyles({ marginLeft: '5px' });
const iconStyles = mergeStyles({ marginLeft: '6px', verticalAlign: 'super' });
const requiredIconStyles = mergeStyles({
  verticalAlign: 'super', marginLeft: '4px', color: '#9f2d30', fontSize: '0.4em',
});

export interface ILabelWithToolTip {
    closeDelay?: number,
    id?: string,
    toolTipText? :string | JSX.Element | JSX.Element[],
    tooltipProps?: ITooltipProps,
    directionalHint? : DirectionalHint,
    label?: string | JSX.Element,
    className?: string,
    showRequired? : boolean,
}
export const LabelWithToolTip : FunctionComponent<ILabelWithToolTip> = (props) => {
  const labelId = useId('tooltip');

  return (
    <TooltipHost
      id={props.id ?? labelId}
      content={props.toolTipText}
      tooltipProps={props.tooltipProps}
      directionalHint={props.directionalHint ? props.directionalHint : DirectionalHint.rightBottomEdge}
      closeDelay={props.closeDelay ? props.closeDelay : TooltipDelay.long}
    >
      <span className={props?.className ? props.className : labelStyles}>{props?.label}</span>
      {props.showRequired && (
      <FontIcon
        aria-label="Required"
        iconName="AsteriskSolid"
        className={requiredIconStyles}
      />
      )}
      {(props.toolTipText || props.tooltipProps)
      && (
      <FontIcon
        aria-labelledby={props.id ?? labelId}
        aria-label="Info"
        iconName="Info"
        className={iconStyles}
        tabIndex={0}
      />
      )}
    </TooltipHost>
  );
};
