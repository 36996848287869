import { createContext } from 'react';
import { ServerConfig } from '../../models/serverConfig';

export interface ConfigContextProps {
  serverConfig: ServerConfig | undefined,
}

export const InitialConfigState: ConfigContextProps = {
  serverConfig: undefined,
};

export const ConfigContext = createContext(InitialConfigState);

export const ConfigConsumer = ConfigContext.Consumer;
