import { initializeIcons } from '@fluentui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { registerAzureIcons } from './modules/azureIcons';

initializeIcons();
registerAzureIcons();

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
