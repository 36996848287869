import { mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, { FunctionComponent, useContext } from 'react';
import hero from '../../assets/gvs_hero.png';
import { HeroContentContext } from '../heroContentProvider/heroContentContext';

const heroStyles = mergeStyles({
  backgroundColor: '#323130',
  backgroundImage: `url(${hero})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'right',
  color: NeutralColors.white,
  display: 'flex',
  justifyContent: 'space-around',
});

const titleStyles = mergeStyles({
  textAlign: 'center',
  padding: '12px',
  backgroundColor: '#F5F5F5',
  color: NeutralColors.black,
  fontWeight: 600,
  fontSize: '20px',
});
export const Hero: FunctionComponent = () => {
  // TODO: This if statement should be removed when we start displaying the hero image on every page
  const heroContentContext = useContext(HeroContentContext);
  if (heroContentContext.getShowHero() !== true) {
    return (
      <></>
    );
  }
  return (
    <div role="banner">
      <div className={heroStyles}>
        {heroContentContext.getHeroContent()}
      </div>
      <div className={titleStyles}>{heroContentContext.getHeroTitle()}</div>
    </div>
  );
};
