import { mergeStyles } from '@fluentui/react';
import React, {
  CSSProperties, FunctionComponent, useEffect, useState,
} from 'react';
import { IPageChromeConfig, onPageChromeConfigChange } from '../../modules/pageChrome/pageChrome';

import { DeepReadonly } from '../util/deepReadonly';

export interface IPageChromeProps {
  children?: JSX.Element | JSX.Element[],
  pageChromeConfig?: IPageChromeConfig,
}

const navContentContainerClassName = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  minHeight: 0,
  minWidth: 0,
});

const contentClassName = mergeStyles({
  flexGrow: 1,
  flexShrink: 1,
  padding: '10px 20px',
  boxSizing: 'border-box',
  height: '100%',
  minHeight: 0,
  minWidth: 0,
});

const appDisplayName = 'Government Validation System';

const PageChrome: FunctionComponent<DeepReadonly<IPageChromeProps>> = (props) => {
  const { pageChromeConfig, children } = props;

  const contentStyle = (pageChromeConfig?.contentBackgroundColor !== undefined) ? ({
    backgroundColor: pageChromeConfig?.contentBackgroundColor,
  } as CSSProperties) : undefined;

  // Editing the title global is ok here, because PageChrome is a singleton
  document.title = (pageChromeConfig?.title === undefined || pageChromeConfig.title.length === 0) ? appDisplayName : `${pageChromeConfig.title} - ${appDisplayName}`;

  return (
    <>
      <div className={navContentContainerClassName}>
        <div className={contentClassName} style={contentStyle}>{children}</div>
      </div>
    </>
  );
};

export interface IPageChromeHostProps {
  children?: JSX.Element | JSX.Element[],
}

export const PageChromeHost: FunctionComponent<DeepReadonly<IPageChromeHostProps>> = (props) => {
  const { children } = props;
  const [pageChromeConfig, setPageChromeConfig] = useState<DeepReadonly<IPageChromeConfig> | undefined>();

  // Listen for new page chrome config from pages
  useEffect(() => {
    const onPageChromeConfigChangeSubscription = onPageChromeConfigChange().subscribe((pageChromeConfig?: DeepReadonly<IPageChromeConfig>) => {
      setPageChromeConfig(pageChromeConfig);
    });

    return () => {
      onPageChromeConfigChangeSubscription.unsubscribe();
    };
  }, []);

  return <PageChrome pageChromeConfig={pageChromeConfig}>{children}</PageChrome>;
};
