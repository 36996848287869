export const enum ValidationType {
  // Source: Azure
  GeneralValidation = 'General Validation',
  AzureGovernmentTrial = 'Azure Government Trial',
  TaskOrderValidation = 'Task Order Validation',
  AzureGovernmentCSP = 'Azure Government CSP',
  ActivateANewAzureGovernmentEnrollment = 'Activate a New Azure Government Enrollment',

  // Source: O365
  O365GCCCSPCustomer = 'O365 GCC CSP Customer',
  O365GCCTrial = 'O365 GCC Trial',
  O365GCCCSPPartner = 'O365 GCC CSP Partner',
}
