import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { CountryRegion, StateProvinceList } from 'generated/clientApi';
import { getRegions, getStates } from 'modules/formData/formData';
import { OrganizationContactInfo } from '../../models/organizationContactInfo';
import { OrganizationInfo } from '../../models/organizationInfo';
import { SupportingInfo } from '../../models/supportingInfo';
import { FormData } from '../../models/formData';
import { CategoryInfo } from '../../models/categoryInfo';
import { FormContext, FormContextProps } from '../../components/formProvider/formContext';
import { LoadingState } from '../../models/loadingState';

export const FormProvider: FunctionComponent = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({});
  const [countryRegions, setCountryRegions] = useState<CountryRegion[]>();
  const [stateProvinceList, setStateProvinceList] = useState<StateProvinceList[]>();
  const [stateProvinceListLoaded, setStateProvinceListLoaded] = useState<LoadingState>(LoadingState.NotLoaded);

  const contextProps: FormContextProps = {
    formData,
    countryRegions,
    stateProvinceList,
    stateProvinceListLoaded,
    updateCategoryInfo: (categoryInfo: CategoryInfo): void => {
      const tempFormData = formData;
      tempFormData.categoryInfo = categoryInfo;
      setFormData(tempFormData);
    },
    updateOrganizationInfo: (organizationInfo: OrganizationInfo): void => {
      const tempFormData = formData;
      tempFormData.organizationInfo = organizationInfo;
      setFormData(tempFormData);
    },
    updateOrganizationContactInfo: (organizationContactInfo: OrganizationContactInfo): void => {
      const tempFormData = formData;
      tempFormData.organizationContactInfo = organizationContactInfo;
      setFormData(tempFormData);
    },
    updateSupportingInfo: (supportingInfo: SupportingInfo): void => {
      const tempFormData = formData;
      tempFormData.supportingInfo = supportingInfo;
      setFormData(tempFormData);
    },
    fetchStates: (regionId: string) => {
      setStateProvinceListLoaded(LoadingState.Loading);
      const states = getStates(regionId);
      setStateProvinceList(states);
      setStateProvinceListLoaded(LoadingState.Loaded);
    },
  };

  useEffect(() => {
    const fetchRegions = () => {
      const regions = getRegions();
      setCountryRegions(regions);
    };
    fetchRegions();
  }, []);

  return (
    <FormContext.Provider value={contextProps}>{children}</FormContext.Provider>
  );
};
