import { Observable, Subject } from 'rxjs';
import { DeepReadonly } from '../../components/util/deepReadonly';

export interface IPageChromeConfig {
  pageId: string,
  title: string,
  isBlade?: boolean,
  contentBackgroundColor?: string,
}

const subject = new Subject<DeepReadonly<IPageChromeConfig>>();

export const updatePageChromeConfig = (pageChromeConfig?: DeepReadonly<IPageChromeConfig>): void => subject.next(pageChromeConfig);

export const onPageChromeConfigChange = (): Observable<DeepReadonly<IPageChromeConfig> | undefined> => subject.asObservable();

export const createHomeChromeConfig = (pageId: string): DeepReadonly<IPageChromeConfig> => ({
  pageId,
  title: 'Home',
  isBlade: true,
  contentBackgroundColor: '#fafafa',
});
