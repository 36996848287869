import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  ICheckboxProps,
  IChoiceGroupOption,
  Link,
  mergeStyles, Spinner, SpinnerSize, Stack, TextField,
} from '@fluentui/react';
import React, {
  FunctionComponent, useCallback, useContext, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormContext } from 'components/formProvider/formContext';
import { SaveButton } from 'components/saving/saveButton';
import { FormToolTab } from 'models/formToolTab';
import { submitForm } from 'modules/formData/formData';
import { successRoute } from 'modules/routes/routes';
import { ValidationType } from 'models/validationType';
import { CategoryType } from 'models/categoryType';
import { CurrentAccount } from 'models/currentAccount';
import { DataRegulation } from 'models/dataRegulation';
import { BusinessAssociations } from 'models/businessAssociations';
import { CheckboxWithToolTip } from 'components/checkboxWithTooltip/checkboxWithTooltip';
import { USTribalOptions } from 'models/usTribalOptions';
import { LabelWithToolTip } from 'components/labelWithTooltip/labelWithTooltip';

const formStyle = mergeStyles({
  maxWidth: '600px',
});

const buttonStyle = mergeStyles({
  marginTop: '3em',
});

const linkStyle = mergeStyles({
  textDecoration: 'underline',
});

const textContainingLinkStyle = mergeStyles({
  color: '#242424',
});

const largeTextFieldStyles = mergeStyles({
  minWidth: 600,
  marginTop: '2em',
  label: {
    fontWeight: 600,
  },
});

const normalTextFieldStyles = mergeStyles({
  maxWidth: 290,
  label: {
    fontWeight: 600,
  },
});

const privacyCheckboxStyles = mergeStyles({
  marginTop: '3em',
});

const onlineAgreementCheckboxStyles = mergeStyles({
  marginTop: '2em',
});

const choiceGroupWrapperStyle = mergeStyles({
  marginTop: '2em',
});

const choiceGroupLabelStyle = mergeStyles({
  fontWeight: 'bold',
});

const spinnerStyle = mergeStyles({
  marginTop: '20px',
  marginBottom: '20px',
});

const dialogTitleStyle = mergeStyles({
  fontWeight: 600,
  marginTop: '-10px',
  textAlign: 'center',
});

const smallTextFieldStyles = mergeStyles({
  width: 320,
  marginTop: '0.6em',
  marginBottom: '0.2em',
});

const optionStyles = mergeStyles({
  marginTop: '0.5em',
});

const enum QuestionOptions {
    Yes = 'Yes',
    No = 'No',
   }

const questionOptions: IChoiceGroupOption[] = [
  { key: QuestionOptions.Yes, text: QuestionOptions.Yes },
  { key: QuestionOptions.No, text: QuestionOptions.No, styles: { root: { marginTop: -1 } } },
];

const nativeBusinessOptions: IChoiceGroupOption[] = [
  { key: BusinessAssociations.NATIVE_AMERICAN_NHO, text: BusinessAssociations.NATIVE_AMERICAN_NHO },
  { key: BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL, text: BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL, styles: { root: { marginTop: -1 } } },
  { key: BusinessAssociations.NATIVE_AMERICAN_CORP, text: BusinessAssociations.NATIVE_AMERICAN_CORP, styles: { root: { marginTop: -3 } } },
];

const usTribalOptions: IChoiceGroupOption[] = [
  { key: USTribalOptions.ALASKA_NATIVE, text: USTribalOptions.ALASKA_NATIVE },
  { key: USTribalOptions.NATIVE_HAWAIIAN, text: USTribalOptions.NATIVE_HAWAIIAN, styles: { root: { marginTop: -1 } } },
  { key: USTribalOptions.OTHER, text: USTribalOptions.OTHER, styles: { root: { marginTop: -3 } } },
];

export interface SupportingTabProps {
  changeActiveTab(targetTab: number): void
}

export const SupportingTab: FunctionComponent<SupportingTabProps> = (props) => {
  const formContext = useContext(FormContext);
  const history = useHistory();
  const { changeActiveTab } = props;
  const [questionFFRDC, setQuestionFFRDC] = useState<boolean | undefined>(formContext.formData.supportingInfo?.questionFFRDC);
  const [questionUSTribalEntity, setQuestionUSTribalEntity] = useState<boolean | undefined>(formContext.formData.supportingInfo?.questionNHO);
  const [questionNHO, setQuestionNHO] = useState<boolean | undefined>(formContext.formData.supportingInfo?.questionNHO);
  const [questionARNC, setQuestionARNC] = useState<boolean | undefined>(formContext.formData.supportingInfo?.questionARNC);
  const [questionUSTribalOther, setQuestionUSTribalOther] = useState<boolean | undefined>(formContext.formData.supportingInfo?.questionUSTribalOther);
  const [privacyChecked, setPrivacyChecked] = useState<boolean | undefined>();
  const [onlineAgreementChecked, setOnlineAgreementChecked] = useState<boolean | undefined>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [parentOrganization, setParentOrganization] = useState<string | undefined>(formContext.formData.supportingInfo?.parentOrganization);
  const [parentOrganizationErrorMessage, setParentOrganizationErrorMessage] = useState<string | undefined>();

  const [entityDescription, setEntityDescription] = useState<string | undefined>(formContext.formData.supportingInfo?.entityDescription);
  const [entityDescriptionErrorMessage, setEntityDescriptionErrorMessage] = useState<string | undefined>();

  const [notes, setNotes] = useState<string | undefined>(formContext.formData.supportingInfo?.notes);
  const [notesErrorMessage, setNotesErrorMessage] = useState<string | undefined>();
  const [hideDialog, setHideDialog] = useState<boolean>(true);

  const getSavedCurrentAccounts = () => {
    const tempCurrentAccounts = new Map();
    formContext.formData.supportingInfo?.currentAccounts?.forEach((key) => { tempCurrentAccounts?.set(key, true); });
    return tempCurrentAccounts;
  };
  const [currentAccounts, setCurrentAccounts] = useState<Map<string, boolean>>(getSavedCurrentAccounts);
  const [accountGSAContract, setAccountGSAContract] = useState<string | undefined>(formContext.formData.supportingInfo?.accountGSAContract);
  const [accountGSAContractErrorMessage, setAccountGSAContractErrorMessage] = useState<string | undefined>();
  const [accountCAGEContract, setAccountCAGEContract] = useState<string | undefined>(formContext.formData.supportingInfo?.accountCAGEContract);
  const [accountCAGEContractErrorMessage, setAccountCAGEContractErrorMessage] = useState<string | undefined>();
  const [accountSAMContract, setAccountSAMContract] = useState<string | undefined>(formContext.formData.supportingInfo?.accountSAMContract);
  const [accountSAMContractErrorMessage, setAccountSAMContractErrorMessage] = useState<string | undefined>();

  const getSavedDataRegulations = () => {
    const tempDataRegulations = new Map();
    formContext.formData.supportingInfo?.dataRegulations?.forEach((key) => { tempDataRegulations?.set(key, true); });
    return tempDataRegulations;
  };
  const [dataRegulations, setDataRegulations] = useState<Map<string, boolean>>(getSavedDataRegulations);

  const getSavedBusinessAssociations = () => {
    const tempBusinessAssociations = new Map();
    formContext.formData.supportingInfo?.businessAssociations?.forEach((key) => { tempBusinessAssociations?.set(key, true); });
    return tempBusinessAssociations;
  };
  const [businessAssociations, setBusinessAssociations] = useState<Map<string, boolean>>(getSavedBusinessAssociations);
  const [businessAssociationNAICSCode, setBusinessAssociationNAICSCode] = useState<string | undefined>(formContext.formData.supportingInfo?.businessAssociationNAICSCode);
  const [businessAssociationNAICSCodeErrorMessage, setBusinessAssociationNAICSCodeErrorMessage] = useState<string | undefined>();

  const getNativeBusinessSelection = () => {
    if (!businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN)) {
      return undefined;
    }

    if (businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL)) return BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL;

    if (businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_NHO)) return BusinessAssociations.NATIVE_AMERICAN_NHO;

    if (businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_CORP)) return BusinessAssociations.NATIVE_AMERICAN_CORP;

    return undefined;
  };
  const [nativeBusinessSelection] = useState<string | undefined>(getNativeBusinessSelection());

  const getUSTribalSelection = () => {
    if (!questionUSTribalEntity) {
      return undefined;
    }

    if (questionNHO) return USTribalOptions.NATIVE_HAWAIIAN;

    if (questionARNC) return USTribalOptions.ALASKA_NATIVE;

    if (questionUSTribalOther) return USTribalOptions.OTHER;

    return undefined;
  };
  const [usTribalSelection] = useState<string | undefined>(getUSTribalSelection());

  const renderLabelWithLink = (props: ICheckboxProps | undefined) => (
    <span>
      {props?.label}
      {' '}
      <Link href={props?.ariaLabel} target="_blank" underline>
        Learn more
      </Link>
    </span>
  );

  const stackTokens = { childrenGap: 10 };
  const horizontalStackTokens = { childrenGap: 20 };

  const isValidationSelected = useCallback((validation : string) => {
    let result;
    formContext.formData.categoryInfo?.validationTypes.forEach((val) => {
      if (val === validation) {
        result = true;
      }
    });
    return result;
  }, [formContext.formData.categoryInfo?.validationTypes]);

  const isNullOrEmpty = (str? : string) => str === undefined || str.length === 0;

  useEffect(() => {
    if ((formContext.formData.categoryInfo?.categoryType === CategoryType.GovernmentEntity && ((questionFFRDC === undefined) || (questionUSTribalEntity === undefined)
    || (questionUSTribalEntity ? (!(questionNHO || questionARNC || questionUSTribalOther)) : false)))
    || (formContext.formData.categoryInfo?.categoryType === CategoryType.SolutionProvider && businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN) ? !(businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL) || businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_CORP) || businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN_NHO)) : false)
    || (privacyChecked === undefined) || (privacyChecked === false) || (!isNullOrEmpty(businessAssociationNAICSCodeErrorMessage))
    || (!isNullOrEmpty(accountCAGEContractErrorMessage)) || (!isNullOrEmpty(accountGSAContractErrorMessage)) || (!isNullOrEmpty(accountSAMContractErrorMessage))
    || (!isNullOrEmpty(parentOrganizationErrorMessage)) || (!isNullOrEmpty(entityDescriptionErrorMessage)) || (!isNullOrEmpty(notesErrorMessage))
    || ((isValidationSelected(ValidationType.AzureGovernmentTrial) || isValidationSelected(ValidationType.AzureGovernmentCSP)) && ((onlineAgreementChecked === undefined) || (onlineAgreementChecked === false)))) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [questionFFRDC, questionNHO, questionARNC, questionUSTribalOther, privacyChecked, onlineAgreementChecked, formContext.formData.categoryInfo?.categoryType, isValidationSelected, businessAssociations, questionUSTribalEntity, businessAssociationNAICSCodeErrorMessage, accountCAGEContractErrorMessage, accountGSAContractErrorMessage, accountSAMContractErrorMessage, parentOrganizationErrorMessage, entityDescriptionErrorMessage, notesErrorMessage]);

  function validateInputForInvalidCharacters(value: string | undefined) {
    const invalidCharRegex = /[<>]/; // a regular expression that matches < or >
    if (value && value.match(invalidCharRegex)) {
      return 'Characters `<` and `>` are not allowed';
    }
    return '';
  }

  useEffect(() => {
    setNotesErrorMessage(validateInputForInvalidCharacters(notes));
  }, [notes]);

  useEffect(() => {
    setParentOrganizationErrorMessage(validateInputForInvalidCharacters(parentOrganization));
  }, [parentOrganization]);

  useEffect(() => {
    setEntityDescriptionErrorMessage(validateInputForInvalidCharacters(entityDescription));
  }, [entityDescription]);

  useEffect(() => {
    setAccountGSAContractErrorMessage(validateInputForInvalidCharacters(accountGSAContract));
  }, [accountGSAContract]);

  useEffect(() => {
    setAccountCAGEContractErrorMessage(validateInputForInvalidCharacters(accountCAGEContract));
  }, [accountCAGEContract]);

  useEffect(() => {
    setAccountSAMContractErrorMessage(validateInputForInvalidCharacters(accountSAMContract));
  }, [accountSAMContract]);

  useEffect(() => {
    setBusinessAssociationNAICSCodeErrorMessage(validateInputForInvalidCharacters(businessAssociationNAICSCode));
  }, [businessAssociationNAICSCode]);

  const onAccountValidationChanged = (key: string, checked?: boolean): void => {
    const tempAccounts = new Map(currentAccounts);
    if (!checked) {
      tempAccounts.delete(key);
    } else {
      tempAccounts.set(key, !!checked);
    }
    setCurrentAccounts(tempAccounts);
  };

  const onDataRegulationChanged = (key: string, checked?: boolean): void => {
    const tempRegulations = new Map(dataRegulations);
    if (!checked) {
      tempRegulations.delete(key);
    } else {
      tempRegulations.set(key, !!checked);
    }
    setDataRegulations(tempRegulations);
  };

  const onBusinessAssociationChanged = (key: string, checked?: boolean): void => {
    const tempBusinessAssociation = new Map(businessAssociations);
    if (!checked) {
      tempBusinessAssociation.delete(key);
      if (key === BusinessAssociations.NATIVE_AMERICAN) {
        // clear sub options
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL);
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_CORP);
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_NHO);
      }
    } else {
      tempBusinessAssociation.set(key, !!checked);
      // clear unchecked sub options
      if (key === BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL) {
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_CORP);
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_NHO);
      } else if (key === BusinessAssociations.NATIVE_AMERICAN_CORP) {
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL);
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_NHO);
      } else {
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_ALASKA_REGIONAL);
        tempBusinessAssociation.delete(BusinessAssociations.NATIVE_AMERICAN_CORP);
      }
    }
    setBusinessAssociations(tempBusinessAssociation);
  };

  const onBusinessNativeOptionsSelect = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    if (option) { onBusinessAssociationChanged(option?.key, true); }
  };

  const onUSTribalOptionsSelect = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    if (option) { onBusinessAssociationChanged(option?.key, true); }
    switch (option?.key) {
      case USTribalOptions.ALASKA_NATIVE:
        setQuestionARNC(true);
        setQuestionNHO(undefined);
        setQuestionUSTribalOther(undefined);
        break;
      case USTribalOptions.NATIVE_HAWAIIAN:
        setQuestionARNC(undefined);
        setQuestionNHO(true);
        setQuestionUSTribalOther(undefined);
        break;
      case USTribalOptions.OTHER:
        setQuestionARNC(undefined);
        setQuestionNHO(undefined);
        setQuestionUSTribalOther(true);
        break;
      default: // do-nothing
    }
  };

  const saveData = () => {
    const selectedAccounts : string[] = [];
    currentAccounts.forEach((value, key) => {
      if (value) {
        selectedAccounts.push(key);
      }
    });

    const selectedRegulations : string[] = [];
    dataRegulations.forEach((value, key) => {
      if (value) {
        selectedRegulations.push(key);
      }
    });

    const selectedBusinessAssociations : string[] = [];
    businessAssociations.forEach((value, key) => {
      if (value) {
        selectedBusinessAssociations.push(key);
      }
    });
    formContext.updateSupportingInfo({
      parentOrganization,
      entityDescription,
      questionFFRDC,
      questionUSTribalEntity,
      questionNHO,
      questionARNC,
      questionUSTribalOther,
      currentAccounts: selectedAccounts,
      accountGSAContract,
      accountCAGEContract,
      accountSAMContract,
      dataRegulations: selectedRegulations,
      businessAssociations: selectedBusinessAssociations,
      businessAssociationNAICSCode,
      notes,
    });
  };

  const onSubmitClicked = async () => {
    // make service request .. show success
    saveData();
    setHideDialog(false);
    try {
      // todo temporarily posting this to API as string, need to figure out the exact format
      const formSubmitted = await submitForm(JSON.stringify(formContext.formData));
      if (formSubmitted) {
        setHideDialog(true);
        history.push(successRoute());
      }
    } catch (error) {
      // logError('Error submitting form', error);
      // todo need error state
    }
  };

  const onPreviousClicked = () => {
    saveData();
    changeActiveTab(FormToolTab.OrganizationInfo);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onQuestionFFRDCSelect = (ev: React.FormEvent<HTMLElement|HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    if (option?.key === QuestionOptions.Yes) {
      setQuestionFFRDC(true);
    } else {
      setQuestionFFRDC(false);
    }
  };

  const onQuestionUSTribalEntitySelect = (ev: React.FormEvent<HTMLElement|HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    if (option?.key === QuestionOptions.Yes) {
      setQuestionUSTribalEntity(true);
    } else {
      setQuestionUSTribalEntity(false);
      setQuestionARNC(undefined);
      setQuestionNHO(undefined);
      setQuestionUSTribalOther(undefined);
    }
  };

  const getDefaultSelectedKey = (value : boolean | undefined) => {
    let result;
    if (value !== undefined) {
      result = value ? QuestionOptions.Yes : QuestionOptions.No;
    }
    return result;
  };

  const renderPrivacyLabelWithLink = () => (
    <span className={textContainingLinkStyle}>
      I have read and agree to the
      {' '}
      <Link href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank" className={linkStyle}>
        Microsoft Government Validation terms and conditions
      </Link>
      , and I have the authority to bind my organization to those terms and conditions
    </span>
  );

  const renderOnlineSubsLabelWithLink = () => (
    <span className={textContainingLinkStyle}>
      I have read and agree to the
      {' '}
      <Link href="https://azure.microsoft.com/en-us/support/legal/subscription-agreement/government/" target="_blank" className={linkStyle}>
        Microsoft Online Subscription Agreement
      </Link>
      , and I have the authority to bind my organization to those terms and conditions.
    </span>
  );

  const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  return (
    <div className={formStyle}>
      <Dialog hidden={hideDialog} modalProps={modalProps}>
        <Stack>
          <div className={dialogTitleStyle}>Your request is being processed</div>
          <Spinner className={spinnerStyle} label="Processing" size={SpinnerSize.medium} />
        </Stack>
      </Dialog>

      <Stack tokens={stackTokens}>
        {formContext.formData.categoryInfo?.categoryType === CategoryType.GovernmentEntity && (
        <>
          <TextField label="Parent organization (if applicable)" placeholder="Enter parent organization name" className={largeTextFieldStyles} onChange={(event, input) => setParentOrganization(input)} defaultValue={parentOrganization} errorMessage={parentOrganizationErrorMessage} />
          <TextField label="If your entity was created or authorized by a statute or regulation, please cite below." placeholder="Please describe" className={largeTextFieldStyles} defaultValue={entityDescription} onChange={(event, input) => setEntityDescription(input)} errorMessage={entityDescriptionErrorMessage} />

          <div className={choiceGroupWrapperStyle}>
            <div className={choiceGroupLabelStyle}>
              Are you a Federally Funded Research and Development Center (FFRDC) or University Affiliated Research Center (UARC)?
            </div>
            <ChoiceGroup
              options={questionOptions}
              onChange={onQuestionFFRDCSelect}
              defaultSelectedKey={getDefaultSelectedKey(questionFFRDC)}
            />
          </div>

          <div className={choiceGroupWrapperStyle}>
            <div className={choiceGroupLabelStyle}>
              Are you a US Tribal Entity?
            </div>
            <ChoiceGroup
              options={questionOptions}
              onChange={onQuestionUSTribalEntitySelect}
              defaultSelectedKey={getDefaultSelectedKey(questionUSTribalEntity)}
            />
          </div>

          {questionUSTribalEntity
          && (
            <div className={choiceGroupLabelStyle} style={{ marginLeft: '1.5em' }}>
              Please specify:
              <ChoiceGroup
                options={usTribalOptions}
                defaultSelectedKey={usTribalSelection}
                onChange={onUSTribalOptionsSelect}
              />
            </div>
          )}
        </>
        )}

        {formContext.formData.categoryInfo?.categoryType !== CategoryType.GovernmentEntity && (
        <>
          <div className={choiceGroupWrapperStyle}>
            <div className={choiceGroupLabelStyle}>
              Please confirm if your company is registered with any of the following government programs (select all that apply)
            </div>
            <Checkbox label={CurrentAccount.GSA} className={optionStyles} checked={!!currentAccounts.get(CurrentAccount.GSA)} onChange={(_event, checked) => { onAccountValidationChanged(CurrentAccount.GSA, checked); }} />
            {!!currentAccounts.get(CurrentAccount.GSA) && (<TextField placeholder="Insert contract number" className={smallTextFieldStyles} defaultValue={accountGSAContract} onChange={(_event, input) => { setAccountGSAContract(input); }} errorMessage={accountGSAContractErrorMessage} />)}

            <CheckboxWithToolTip
              checkBoxProps={{
                label: CurrentAccount.CAGE,
                className: optionStyles,
                checked: !!currentAccounts.get(CurrentAccount.CAGE),
                onChange: (_event, checked) => { onAccountValidationChanged(CurrentAccount.CAGE, checked); },
              }}
              toolTipText={'This must be a valid CAGE code for your company and must have a status of "Active"'}
            />
            {!!currentAccounts.get(CurrentAccount.CAGE) && (<TextField placeholder="Insert contract number" className={smallTextFieldStyles} defaultValue={accountCAGEContract} onChange={(_event, input) => { setAccountCAGEContract(input); }} errorMessage={accountCAGEContractErrorMessage} />)}

            <CheckboxWithToolTip
              checkBoxProps={{
                label: CurrentAccount.SAM,
                className: optionStyles,
                checked: !!currentAccounts.get(CurrentAccount.SAM),
                onChange: (_event, checked) => { onAccountValidationChanged(CurrentAccount.SAM, checked); },
              }}
              toolTipText="This must be a valid SAM.gov registered UEI with the Registration Status as “Active Registration” and the Purpose of Registration as “All Awards”."
            />
            {!!currentAccounts.get(CurrentAccount.SAM) && (<TextField placeholder="Insert contract number" className={smallTextFieldStyles} defaultValue={accountSAMContract} onChange={(_event, input) => { setAccountSAMContract(input); }} errorMessage={accountSAMContractErrorMessage} />)}
          </div>

          <div className={choiceGroupWrapperStyle}>
            <div className={choiceGroupLabelStyle}>
              Are you an entity holding any of the following types of data subject to government regulation? (select all that apply)
            </div>
            <Checkbox label={DataRegulation.CJI} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.CJI)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.CJI, checked); }} />
            <Checkbox label={DataRegulation.CUI} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.CUI)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.CUI, checked); }} />
            <Checkbox label={DataRegulation.DFARS} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.DFARS)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.DFARS, checked); }} />
            <Checkbox label={DataRegulation.DOD} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.DOD)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.DOD, checked); }} />
            <Checkbox label={DataRegulation.DOE} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.DOE)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.DOE, checked); }} />
            <Checkbox label={DataRegulation.IRS} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.IRS)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.IRS, checked); }} />
            <Checkbox label={DataRegulation.ITAR} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.ITAR)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.ITAR, checked); }} />
            <Checkbox label={DataRegulation.NERC} className={optionStyles} checked={!!dataRegulations.get(DataRegulation.NERC)} onChange={(_event, checked) => { onDataRegulationChanged(DataRegulation.NERC, checked); }} />
          </div>

          <div className={choiceGroupWrapperStyle}>
            <LabelWithToolTip
              className={choiceGroupLabelStyle}
              label={(
                <>
                  Are you registered with the
                  {' '}
                  <Link href="https://www.sba.gov/" target="_blank" underline>
                    Small Business Association
                  </Link>
                  ? If so, which type? (select all that apply)
                </>
              )}
              toolTipText="The government tries to award a certain percentage of all federal prime contracting dollars to small businesses that meet certain socio-economic conditions. The SBA provides several programs to help small businesses win federal contracts. "
            />
            <Checkbox label={BusinessAssociations.BUSINESS_DEV} ariaLabel="https://www.sba.gov/federal-contracting/contracting-assistance-programs/8a-business-development-program" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.BUSINESS_DEV)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.BUSINESS_DEV, checked); }} />
            <Checkbox label={BusinessAssociations.HUBZONE} ariaLabel="https://www.sba.gov/federal-contracting/contracting-assistance-programs/hubzone-program" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.HUBZONE)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.HUBZONE, checked); }} />
            <Checkbox label={BusinessAssociations.WOMEN_OWNED} ariaLabel="https://www.sba.gov/federal-contracting/contracting-assistance-programs/women-owned-small-business-federal-contract-program" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.WOMEN_OWNED)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.WOMEN_OWNED, checked); }} />
            <Checkbox label={BusinessAssociations.VETERAN_ASSISTANCE} ariaLabel="https://www.sba.gov/federal-contracting/contracting-assistance-programs/veteran-contracting-assistance-programs" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.VETERAN_ASSISTANCE)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.VETERAN_ASSISTANCE, checked); }} />
            <Checkbox label={BusinessAssociations.MINORITY_OWNED} ariaLabel="https://www.sba.gov/business-guide/grow-your-business/minority-owned-businesses" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.MINORITY_OWNED)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.MINORITY_OWNED, checked); }} />
            <Checkbox label={BusinessAssociations.DISADVANTAGE} ariaLabel="https://www.sba.gov/federal-contracting/contracting-assistance-programs/small-disadvantaged-business" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.DISADVANTAGE)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.DISADVANTAGE, checked); }} />
            <Checkbox label={BusinessAssociations.NATIVE_AMERICAN} ariaLabel="https://www.sba.gov/business-guide/grow-your-business/native-american-owned-businesses" onRenderLabel={renderLabelWithLink} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.NATIVE_AMERICAN, checked); }} />
            {businessAssociations.get(BusinessAssociations.NATIVE_AMERICAN)
            && (
            <ChoiceGroup
              style={{ marginLeft: '1.5em' }}
              options={nativeBusinessOptions}
              defaultSelectedKey={nativeBusinessSelection}
              onChange={onBusinessNativeOptionsSelect}
            />
            )}

            <Checkbox label={BusinessAssociations.NAICS_CODE} className={optionStyles} checked={!!businessAssociations.get(BusinessAssociations.NAICS_CODE)} onChange={(_event, checked) => { onBusinessAssociationChanged(BusinessAssociations.NAICS_CODE, checked); }} />
            {!!businessAssociations.get(BusinessAssociations.NAICS_CODE) && (<TextField placeholder="Separate by commas if you have multiple codes" className={smallTextFieldStyles} defaultValue={businessAssociationNAICSCode} onChange={(_event, input) => { setBusinessAssociationNAICSCode(input); }} errorMessage={businessAssociationNAICSCodeErrorMessage} />)}
          </div>
        </>
        )}
        <TextField label="Additional notes (optional)" placeholder="Enter any additional notes" multiline rows={10} className={normalTextFieldStyles} onChange={(event, input) => setNotes(input)} errorMessage={notesErrorMessage} />

        <Checkbox className={privacyCheckboxStyles} onRenderLabel={renderPrivacyLabelWithLink} onChange={(input, checked) => { setPrivacyChecked(checked); }} />
        {(isValidationSelected(ValidationType.AzureGovernmentTrial) || isValidationSelected(ValidationType.AzureGovernmentCSP)) && (<Checkbox className={onlineAgreementCheckboxStyles} onRenderLabel={renderOnlineSubsLabelWithLink} onChange={(input, checked) => { setOnlineAgreementChecked(checked); }} />)}

      </Stack>

      <Stack horizontal tokens={horizontalStackTokens}>
        <SaveButton className={buttonStyle} defaultText="Submit" onSave={onSubmitClicked} disabled={buttonDisabled} />
        <DefaultButton text="Previous" onClick={onPreviousClicked} className={buttonStyle} />
      </Stack>
    </div>
  );
};
