import { CategoryInfo } from 'models/categoryInfo';
import { OrganizationContactInfo } from 'models/organizationContactInfo';
import { OrganizationInfo } from 'models/organizationInfo';
import { SupportingInfo } from 'models/supportingInfo';
import { FormData } from 'models/formData';
import { createContext } from 'react';
import { CountryRegion, StateProvinceList } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';

export interface FormContextProps {
    formData: FormData,
    countryRegions?: CountryRegion[],
    stateProvinceList?: StateProvinceList[],
    stateProvinceListLoaded?: LoadingState,
    updateCategoryInfo: (categoryInfo : CategoryInfo) => void,
    updateOrganizationInfo: (organizationInfo: OrganizationInfo) => void,
    updateOrganizationContactInfo: (organizationContactInfo: OrganizationContactInfo) => void,
    updateSupportingInfo: (supportingInfo : SupportingInfo) => void,
    fetchStates: (regionId : string) => void,
  }

export const FormContext = createContext<FormContextProps>({
  formData: {},
  updateCategoryInfo: (_categoryInfo : CategoryInfo): void => {
    // do nothing for the base case
  },
  updateOrganizationInfo: (_organizationInfo: OrganizationInfo): void => {
    // do nothing for the base case
  },
  updateOrganizationContactInfo: (_organizationContactInfo: OrganizationContactInfo): void => {
    // do nothing for the base case
  },
  updateSupportingInfo: (_supportingInfo : SupportingInfo): void => {
    // do nothing for the base case
  },
  fetchStates: (_regionId : string): void => {
    // do nothing for the base case
  },
});

export const FormConsumer = FormContext.Consumer;
