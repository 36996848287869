import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const herContentStyles = mergeStyles({
  marginLeft: '40px',
  marginTop: '30px',
  marginBottom: '40px',
});

const titleStyles = mergeStyles({
  fontSize: '28px',
  fontWeight: 'bold',
});

const descriptionStyles = mergeStyles({
  fontSize: '12px',
  lineHeight: '1.5em',
  width: '50%',
});

export const SuccessPageHeroContent: FunctionComponent = () => (
  <>
    <div className={herContentStyles}>
      <div className={titleStyles}>Government Community Cloud Eligibility Intake Form</div>
      <div className={descriptionStyles}>
        The intent of this form is to begin verifying your eligibility as a US Federal, State, Local, or Tribal entity, a government partner, or a commercial entity holding specific government regulated data, that is looking to gain access to the government community cloud set of products. If additional information is required upon the submission of this form, we will contact you via email regarding next steps.
        <br />
        <br />
        <br />
        We look forward to helping you accelerate your cloud journey.
      </div>
    </div>
  </>
);
